import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR } from "../actiontype"
import axios from "axios"
import { baseUrlApi } from "../BaseURL"


const GET_CATEGORIES = baseUrlApi + "Category/GetList"

export const getCategoriesAction = (actions, dispatch) =>
    dispatch => {
        axios.get(GET_CATEGORIES)
            .then(result => {
                dispatch({ type: GET_CATEGORIES_SUCCESS, payload: result.data })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    dispatch(actions.logout())
                }
                else {
                    dispatch({ type: GET_CATEGORIES_ERROR, payload: "kategorilere ulaşırken hata ile karşılaşıldı" })
                }
            })
    }


