
import {GET_STATUSANONYMOUS_SUCCESS} from "../actiontype"

const initialAuthState = {
  statusAnonymous: []
};

export const statusAnonymousReducer = (state = initialAuthState.statusAnonymous, action) => {
  switch (action.type) {
    case GET_STATUSANONYMOUS_SUCCESS: 
      return action.payload
    
    default:
      return state;
  }
}