import React,{useEffect} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../../_metronic/_helpers";
import { useDispatch, useSelector } from 'react-redux'
import { useIntl, FormattedMessage } from "react-intl";
import { Button } from '@material-ui/core';
import { useLocation, useParams,useHistory } from "react-router-dom";
import { getGiftAnonymousAction  } from "../../../../../Redux/actions/GiftAnonymousAction"
export function TableWidget({ className }) {



  const { eventanonid } = useParams();
  const dispatch = useDispatch()
  const history=useHistory()

  useEffect(() => {
    dispatch(getGiftAnonymousAction(eventanonid))
  }, [])

  const AnonymousGifts = useSelector(state => state.giftAnonymous.giftsAnonymous)
  return (
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header ">
          <h3 className="card-title align-items-center   flex-column">
          
            <span className="text-muted mt-3 font-weight-bold font-size-sm"></span>
          </h3>
          <div className="card-toolbar">
 
            
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive" style={{display:"block",width:"100%",overflowX:"auto"
}}>
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75">Dilek Kutusu</span></th>
                
                  <th style={{minWidth: "100px"}}><FormattedMessage id="actual_price" /></th>
                  <th style={{minWidth: "100px"}}>Link</th>
              
                
                </tr>
                </thead>
                <tbody>
                {
                  AnonymousGifts.map(gift =>

                    <tr>
                 <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="symbol-label">
                              <span>
                                <img style={{width:"50px", height:"50px"}} src={gift.image} />
                              </span>
                            </span>
                          </div>
                          <div>
                            <a  onClick={()=>history.push("/gift/giftdetail/" + gift.id)} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"> {gift.name} </a>

                          </div>
                        </div>
                      </td>
                  <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      TL {gift.price}
                      </span>
                   
                  </td>
                  
                  <td>
                  <Button type="submit" onClick={()=>history.push(gift.link)}
                style={{ color: "#fff", backgroundColor: "#b22f91" }}
               
               variant="contained">Dilek</Button>

                   
                  </td>
                
                 
                
                 
                 
                  
                </tr>
                  
                  
                  )}
                
               
               
                
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}