import axios from "axios"
import {
 GET_GIFT_ANONYMOUS_ERROR,
 GET_GIFT__ANONYMOUS_SUCCESS
} from "../actiontype"



export const getGiftAnonymousAction = (eventId) => dispatch => {
    axios.get("http://yepp-api.azurewebsites.net/api/Gift/GetGiftListByEventIdAsAnonymous?EventId="+ eventId)
      .then(result => {
        dispatch({ type: GET_GIFT__ANONYMOUS_SUCCESS, payload: result.data })
      })
      .catch(error => {
        dispatch({ type: GET_GIFT_ANONYMOUS_ERROR, payload: error.response })
      })
  }