import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';

import { Button } from '@material-ui/core/';
import { Modal } from 'react-responsive-modal';
import { FaShare } from 'react-icons/fa'
import Share from './Share'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "./modal.css";
import { useIntl, FormattedMessage } from "react-intl";

const Modal1 = ({ baslik, id }) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <div className="save_share" onClick={() => setOpen(true)} />

      <div className="save_share " onClick={() => setOpen(true)}>

        <Button
          variant="contained"
          color="default"
          startIcon={<CloudUploadIcon />}
        >
          <FormattedMessage id="save_and_shara" />
        </Button>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <Share eventid={id} baslik={baslik} />
      </Modal>
    </div>
  );
}
export default Modal1;
