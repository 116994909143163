import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { requestEmail } from "./redux/emailAction";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { truncate } from "lodash";
import PopupModal from "../../../../app/modules/UserProfile/PopupModal";


const initialValues = {
  email: "",
};

export function Footer() {
  const intl = useIntl();
  const uiService = useHtmlClassService();
  const [durum, setDurum] = useState(false)

  const [alertField, setAlertField] = useState({
    title: "",
    text: "",
    show: false,
    color: "#23ba47"
  })
  const dispatch = useDispatch();

  const footerValid = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({
        id: "alert_email_format",
      }))
      .required("boş girilemez"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: footerValid,
    onSubmit: values => {
      dispatch(requestEmail(values.email, setAlertField))



    },
  });


  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer  newClasses py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center  newClasses`}
      >



        <div>
          <div>
            <div className="widgets vc_row">
              <div className="vc_col-lg-6 vc_col-md-6 vc_col-sm-6 widgets-column">


                <ul className="widget-ul">
                  <li >
                    <div className="socialbar small outline new-tab-links ">
                      <a href="https://www.facebook.com/yeppapp" className="social rounded-circle ">
                        <i className="fab fa-facebook btn-flat"></i>
                      </a>

                      <a href="https://www.instagram.com/yepp.app/" className="social rounded-circle">
                        <i className="fab fa-instagram btn-flat"></i>
                      </a>

                      <a href="https://www.linkedin.com/company/yepp-app/" className="social rounded-circle">
                        <i className="fab fa-linkedin btn-flat"></i>
                      </a>

                      <a href="https://twitter.com/yeppapp" className="social rounded-circle">
                        <i className="fab fa-twitter btn-flat"></i>
                      </a>

                      <a href="https://www.youtube.com/channel/UC_o65dLvlB12t--enK7v63w" className="social rounded-circle">
                        <i className="fab fa-youtube btn-flat"></i>
                      </a>


                    </div>
                    <div>
                      <span className="copyright"><FormattedMessage id="footer_copyright" /> &copy; YEPP {new Date().getFullYear()}</span>
                    </div>
                  </li></ul>

                <div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2c417d9 footer-contact" data-id="2c417d9" data-element_type="column">
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-9552a32 elementor-widget elementor-widget-heading" data-id="9552a32" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <h6 className="elementor-heading-title elementor-size-default ilk about "><FormattedMessage id="footer_conctanc" /></h6>
                </div>
              </div>

              <div className="elementor-element elementor-element-01ac79c elementor-widget elementor-widget-heading" data-id="01ac79c" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <div className="elementor-heading-title ">yepp@yepp.app</div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="elementor-column   elementor-element elementor-element-2c417d9 footer-contact" data-id="2c417d9" data-element_type="column">
          <div className="elementor-column-wrap1 elementor-element-populated">
            <div className="elementor-widget-wrap container">
              <div className="elementor-element elementor-element-9552a32 elementor-widget elementor-widget-heading" data-id="9552a32" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <h6 className="elementor-heading-title  ilk about "><FormattedMessage id="footer_about" /></h6>
                </div>
              </div>
              <div className="elementor-element elementor-element-72906b9 elementor-widget elementor-widget-heading" data-id="72906b9" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <div className="elementor-heading-title "><FormattedMessage id="footer_tex1" /></div>
                </div>
              </div>


              <div className="elementor-element elementor-element-397a7e9 elementor-widget elementor-widget-heading" data-id="397a7e9" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <div className="elementor-heading-title "> <FormattedMessage id="footer_tex2" /></div>
                </div>
              </div>





            </div>
          </div>
        </div>


        <ul>
          <li className="widget widget_text">

            <div className="textwidget">
              <div className="wpcf7">
                <div className="screen-reader-response">
                  <p></p>
                  <ul></ul>

                </div>
                <form onSubmit={formik.handleSubmit} className="wpcf7-form init">
                  <h3 className="elementor-heading-title title"><FormattedMessage id="footer_subscribe" /></h3>
                  <div className="subscribe-form">
                    <div className="fv-plugins-icon-container">
                      <span className="wpcf7-form-control-wrap your-email" data-children-count="1">
                        <span className="wpcf7-form-control-wrap your-email">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            size="40"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            className={`wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email ${getInputClasses("email")}`}
                            {...formik.getFieldProps("email")}
                            aria-required="true"
                            aria-invalid="false"
                            placeholder={intl.formatMessage({ id: "footer_placholder", })} />
                        </span>
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                        ) : null}
                      </span>
                    </div>
                    <br></br>
                    <div className="subscribe-form-checkbox">
                      <span className="wpcf7-form-control-wrap mailjet-opt-in">
                        <span className="wpcf7-form-control wpcf7-acceptance" >
                          <span className="wpcf7-list-item">
                            <label>
                              <input type="checkbox" name="mailjet-opt-in" checked={durum}
                                onChange={() => setDurum(!durum)} />
                              <span className="wpcf7-list-item-label elementor-heading-title"><FormattedMessage id="footer_privacy_policy1" /> <a className="elementor-heading-title" href="/privacy-policy/"><FormattedMessage id="footer_privacy_policy2" /> </a>
                              </span>
                            </label>
                          </span>
                        </span>
                      </span>
                    </div>

                    <PopupModal
                      {...alertField}
                      show={alertField.show}
                      onHide={() => setAlertField(value => ({ ...value, show: false }))}
                    />

                    <button disabled={!durum} type="submit" style={{ color: "white", backgroundColor: "#000" }} className="btn font-weight-bolder font-size-sm" >
                      {intl.formatMessage({ id: "subscribe", })}
                    </button>

                    <p></p>

                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
