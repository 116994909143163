
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";

import { getStatusAction } from "../../../modules/Redux/actions/statusActions"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import logo from '../../../../yepp_media/logos/yepp_not_text.jpg'
import { useIntl, FormattedMessage } from "react-intl";
import { MyPage } from '../../../pages/MyPage'


import Anonim from '../../ECommerce/pages/products/product-edit/Anonim'
import '../../../../yepp_media/mainpage_assets/css/login.css'
export function AuthPage() {
  const dispatch = useDispatch()
  const intl = useIntl();



 

 
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          {/*logo alanı */}



          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative  overflow-hidden">
            {/*begin::Content header*/}
            <div className="position-absolute top-0" >


              <img src={logo} alt="logo" width="200" height="170" className="headerlogo" />



              <Link
                to="/auth/mypage"
                className="digital"
                id="kt_login_signup"
              >
                <FormattedMessage id="title" />

              </Link>
            </div>

            <div className="position-absolute top-30 right-0 text-right signuplogin  ">
              <Link
                to="/auth/login"
                className=" font-weight-bold btn btn-primary btn-xl text-uppercase js-scroll-trigger login"

              >
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />

              </Link>

              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2 btn btn-primary btn-xl text-uppercase js-scroll-trigger signup"
                id="kt_login_signup"
              >
                <FormattedMessage id="AUTH.DONT.HAVE.ACCOUNT.TEXT2" />

              </Link>
            </div>
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex  flex-center mt-30 mt-lg-40">
              <Switch>
                <ContentRoute path="/mainpage" component={MyPage} />
                <ContentRoute path="/event/eventdetailanonymous/:eventanonid" component={Anonim} />

                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/*" to="/mainpage" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}

            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}









