export const GET_CATEGORIES_SUCCESS = "[Get Categories] Action"
export const GET_CATEGORIES_ERROR = "[Get Categories Error] Action"

export const GET_STATUS_SUCCESS = "[Get Status] Action"
export const GET_STATUS_ERROR = "[Get Status Error] Action"

export const GET_STATUSANONYMOUS_SUCCESS = "[Get StatusAnonymuos] Action"
export const GET_STATUSANONYMOUS_ERROR = "[Get StatusAnonymuos Error] Action"

export const GET_CATEGORIESANONYMOUS_SUCCESS = "[Get CategoriesAnonymuos] Action"
export const GET_CATEGORIESANONYMOUS_ERROR = "[Get CategoriesAnonymous Error] Action"
export const GET_GIFT_SUCCESS = "[Get Gift] Action"
export const GET_GIFT_ERROR = "[Get Gift Error] Action"

export const DELETE_GIFT_BY_ID_SUCCESS = "[Delete Gift] Action"
export const DELETE_GIFT_BY_ID_ERROR = "[Delete Gift Error] Action"

export const UPDATE_GIFT_QUANTITY_DEC_SUCCESS = "[Update Gift>Quantity] Action"
export const UPDATE_GIFT_QUANTITY_DEC_ERROR = "[Update Gift>Quantity Error] Action"

export const UPDATE_GIFT_QUANTITY_INC_SUCCESS = "[Update Gift>QuantityInc] Action"
export const UPDATE_GIFT_QUANTITY_INC_ERROR = "[Update Gift>QuantityInc Error] Action"

export const GET_PRIORITY_SUCCESS = "[Get Priority] Action"
export const GET_PRIORITY_ERROR = "[Get Priority Error] Action"

export const CREATE_GIFT_SUCCESS = "[Create Gift] Action"
export const CREATE_GIFT_ERROR = "[Create Gift Error] Action"

export const GET_GIFT_WEB_SCRAPING_SUCCESS = "[Get Gift Web Scraping] Action"
export const GET_GIFT_WEB_SCRAPING_ERROR = "[Get Gift Web Scraping Error] Action"

export const GET_GIFT_DETAIL_BY_ID_SUCCESS = "[Get Gift Detail] Action"
export const GET_GIFT_DETAIL_BY_ID_ERROR = "[Get Gift Detail Error] Action"

export const GET_GIFT_MARKET_LIST_SUCCESS="[Get Gift Market List] Action"
export const GET_GIFT_MARKET_LIST_ERROR="[Get Gift Market List Error] Action"

export const UPDATE_GIFT_SUCCESS="[Update Gift] Action"
export const UPDATE_GIFT_ERROR="[Update Gift Error] Action"

export const GET_GIFT__ANONYMOUS_SUCCESS = "[Get GiftAnonymous] Action"
export const GET_GIFT_ANONYMOUS_ERROR = "[Get GiftAnonymous Error] Action"