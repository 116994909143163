import axios from "axios";
import { actions } from "../app/modules/Auth";


export default async function setupAxios(axios, store) {

  await axios.interceptors.request.use(
    config => {
      const { auth: { authToken } } = store.getState();
      if (authToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${authToken}`,
          "Access-Control-Allow-Origin": "*",
        }
      }
      return config;
    },
    err => {
      Promise.reject(err)
    }
  );

  axios.interceptors.response.use(
    response => {
      return response;
    }
    // ,
    // err => {
    //   dispatchEvent(actions.logout())
    // }
  );


}
