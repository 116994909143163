import React, { Component } from 'react';
import 'react-bootstrap-buttons/dist/react-bootstrap-buttons.css';

import 'react-bootstrap-buttons/dist/react-bootstrap-buttons.css';
import "./payment.css";

import InputMask from 'react-input-mask';
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.formref = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.formref.current.classList.add('active');
    }, 100);
  }

  render() {
    return (
      <div className="formcard" ref={this.formref}>
        <div className="form">
          <div className="card space icon-relative formarea">
            <InputMask mask="" type="email" maskChar={null} name="billingEmail" onChange={this.props.handleTextChange} className="input" placeholder="Email" />
          </div>
          <div className="card space icon-relative formarea">
            <input type="text" name="nameLastname" maxLength="50" value={this.props.state.nameLastname} onChange={this.props.handleTextChange} className="input" placeholder="Card holder Name/Surname" />
            <i className="fas fa-user"></i>
          </div>
          <div className="card space icon-relative formarea">
            <InputMask mask="9999-9999-9999-9999" type="text" name="number" onChange={this.props.handleTextChange} className="input" placeholder="Kart Numarası" />
            <i className="far fa-credit-card"></i>
          </div>
          <div className="card-grp space">
            <div className="card-item icon-relative formarea">
              <InputMask mask="99 / 9999" type="text" name="monthYear" onChange={this.props.handleTextChange} className="input" placeholder="ay / yıl" />
              <i className="far fa-calendar-alt"></i>
            </div>
            <div className="card-item icon-relative formarea">
              <InputMask mask="999" type="text" name="cvv" onChange={this.props.handleTextChange} className="input" placeholder="cvv" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;