import React from "react";

export function DropdownMenu4() {
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-5">
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-avatar"></i></span>
                    <span className="navi-text">Evlilik </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-group"></i></span>
                    <span className="navi-text">Bebeğimiz Geliyor</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon-open-box"></i></span>
                    <span className="navi-text">Yeni Ev</span>
                    <span className="navi-link-badge">
               
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-gift"></i></span>
                    <span className="navi-text">Doğum Günü</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon-calendar-1"></i></span>
                    <span className="navi-text">Özel Gün</span>
                    <span className="navi-link-badge">
               
            </span>
                </a>
            </li>
        </ul>
        {/*end::Navigation*/}

    </>
}


