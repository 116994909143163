import axios from "axios";
import { baseUrlApi } from "../../../Redux/BaseURL";


const PRODUCTS_URL = "api/products";
const GET_PRODUCTS_URL = baseUrlApi + "Event/GetList";
const GET_PRODUCTS_BYID_URL = baseUrlApi + "Event/GetEventListByUserId?userId=";
const DELETE_EVENT_BYID_URL = baseUrlApi + "Event/DeleteEventById?id="
const UPDATE_EVENT_URL = baseUrlApi + "Event/UpdateEvent"
const ADD_EVENT_URL = baseUrlApi + "Event/AddEvent"//add işleminide buraya ekle
const ADD_EVENT_IMAGE_URL = baseUrlApi + "Event/CreateImage"
const GET_ANONY_EVENT_DETAILS = baseUrlApi + "Event/GetDetailEventAsAnonymous?id="

// CREATE =>  POST: add a new product to the server
export function createProduct(event) {
  return axios.post(ADD_EVENT_URL, {
    "UserId": event.userid,
    "CategoryId": event.categoryId,
    "Title": event.title,
    "Description": event.description,
    "Image": "",
    "TotalBalance": event.totalbalance,
    "EndDate": event.endDate,
    "StatusId": event.statusId
  });
}

export function createEventImage({ image, eventid }) {
  return axios.post(ADD_EVENT_IMAGE_URL, {
    EventId: eventid,
    Img: image
  });
}

// READ
export function getAllProducts() {
  return axios.get(GET_PRODUCTS_URL);
}

export function getAnonyEventDetail(eventanonid) {
  return axios.get(GET_ANONY_EVENT_DETAILS + eventanonid);
}

export function getProductById(productId) {
  return axios.get(GET_PRODUCTS_BYID_URL + productId);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
  return axios.post(`${PRODUCTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(event) {
  return axios.put(UPDATE_EVENT_URL, {
    "id": event.id,
    "EventUpdateOptions":
    {
      "UserId": event.userid,
      "CategoryId": event.categoryId,
      "Title": event.title,
      "Description": event.description,
      "Image": event.image,
      "TotalBalance": event.totalbalance,
      "EndDate": event.endDate,
      "StatusId": event.statusId
    }

  });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status
  });
}

// DELETE => delete the product from the server
export function deleteProduct(eventId) {
  return axios.delete(DELETE_EVENT_BYID_URL + eventId);
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}
