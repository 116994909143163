import axios from "axios";
import https from "https"
import { baseUrlApi, baseUrlId } from "../../Redux/BaseURL";
import { actions } from "./authRedux"

//-- Mock proccess path start
export const ME_URL = ""
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = baseUrlApi + "user/AddUser";
export const REQUEST_PASSWORD_URL = baseUrlApi +"user/ForgotPassword";
//-- Mock proccess path end
const YEPP_API_USER_INFO = baseUrlApi + "user/GetDetailUser?guid=";

const YEPP_IDENTITY = baseUrlId + "api/login";
const YEPP_USER_CONNECT_INFO = "http://yepp-id.azurewebsites.net/connect/userinfo"

const YEPP_GET_DETAIL_USER = baseUrlApi + "user/GetDetailUserByEmail?email="


const option = {
  headers: {
    'secret-key': process.env.REACT_APP_NOT_SECRET_CODE,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
  }
};


async function login(email, password, dispatch) {
  var payload = {
    UserName: email,
    Password: password,
    ClientId: "yepp.test".toLowerCase()
  }
  //TODO : Login


  var getToken = axios.post(YEPP_IDENTITY, payload, option)
  await getToken.then(async function (axiosTestResult) {
    await userConnectInfo(axiosTestResult, email, dispatch)
  })
  return getToken;
}

async function userConnectInfo(axiosTestResult, email, dispatch) {
  const options = {
    headers: {
      Authorization: `Bearer ${axiosTestResult.data.access_token}`,
      ...option
    }
  };
  await axios.get(YEPP_GET_DETAIL_USER + email, options)
    .then(function (result) {

      localStorage.setItem("access-token", axiosTestResult.data.access_token)
      localStorage.setItem("expires_in", axiosTestResult.data.expires_in)
      localStorage.setItem("token_type", axiosTestResult.data.token_type)
      dispatch(actions.login(axiosTestResult.data.access_token))
      dispatch(actions.fulfillUser(result.data))
    })
}

// function getUserByInfo() {
//   return axios.get(YEPP_API_USER_INFO + localStorage.getItem("user_id"))
// }

export function register(email, name, surname, password, telephone) {
  return axios.post(REGISTER_URL, {
    Password: password,
    Name: name,
    SurName: surname,
    Email: email,
    Telephone: telephone,
    Language: localStorage.getItem("i18nConfig")
      ? JSON.parse(localStorage.getItem("i18nConfig")).selectedLang
      : "tr",
    Kvkk_Checked: true
  })

}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, {
    Email: email
  });
}



export { login }