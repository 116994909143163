import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { categoryReducer } from "../app/modules/Redux/reducers/categoryReducer"
import { statusReducer } from "../app/modules/Redux/reducers/statusReducer"
import { statusAnonymousReducer } from "../app/modules/Redux/reducers/statusanonymousReducer"
import { categoryAnonymousReducer } from "../app/modules/Redux/reducers/categoryanonymousReducer"
import giftReducer from '../app/modules/Redux/reducers/GiftReducer'
import { priorityReducer } from '../app/modules/Redux/reducers/priorityReducer'
import giftScrapingReducer from "../app/modules/Redux/reducers/giftScrapingReducer";
import {giftAnonymousReducer} from '../app/modules/Redux/reducers/GiftAnonymousReducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  categories: categoryReducer,
  status: statusReducer,
  categoryAnonymousReducer: categoryAnonymousReducer,
  statusAnonymousReducer: statusAnonymousReducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  gifts: giftReducer,
  priorityReducer: priorityReducer,
  scraping: giftScrapingReducer,
  giftAnonymous:giftAnonymousReducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}


