import React from 'react'
import { FormattedMessage } from "react-intl";

import resim1 from '../../yepp_media/mainpage_assets/img/portfolio_image/1.png'
import resim2 from '../../yepp_media/mainpage_assets/img/portfolio_image/2.png'
import resim3 from '../../yepp_media/mainpage_assets/img/portfolio_image/3.png'
import resim4 from '../../yepp_media/mainpage_assets/img/portfolio_image/4.png'
import resim5 from '../../yepp_media/mainpage_assets/img/portfolio_image/5.png'
import resim6 from '../../yepp_media/mainpage_assets/img/portfolio_image/6.png'

const  Portfolio = () => {
    return (
        <section className="bg-light page-section" id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase"><FormattedMessage id="title" /></h2>
              <h3 className="section-subheading text-muted"><FormattedMessage id="mypage_portfolio" /></h3>
            </div>
          </div>
          <div className="row">
            
              
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid" src={resim1}   alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid" src={resim2} alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid"  src={resim3}  alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid" src={resim4}  alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid" src={resim5}  alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" onClick={()=>alert("çalışıyorr")}>
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x"></i>
                                </div>
                            </div>
                            <img className="img-fluid" src={resim6} alt="portfolio_img" />
                           
                        </a>
                        <div className="portfolio-caption">
                            
                           
                        </div>
                    </div>
                   
                   
                    
                     
                
            
          </div>
        </div>
      </section>
    )
}
export default Portfolio;