import React, { useState, useLayoutEffect, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import "./custom.css";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { TableWidget } from "./widgets/TableWidget";
import logo from '../../../../../../yepp_media/logos/yepp_not_text.jpg'

import Modal1 from "./ShareSocial/Modal";
import Container from './ContainerComponent'

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../../_metronic/_partials/controls";
import "./anonim.css";
import { useParams } from "react-router-dom";
import { getEventAnonyByIdAction } from "../../../_redux/products/productsActions";
import { imageSizeFonk } from "./imageSize";
import axios from "axios";

function Anonim({ intl }) {
  const url = window.location.href;
  const [statePicture, setstatePicture] = useState("");
  const [ip, setIP] = useState('');
  const [visible, setVisible] = useState(false);
  const { eventanonid } = useParams();
  const dispatch = useDispatch();
  const eventanony = useSelector((state) => state.products);
  const [imageSize, setimageSize] = useState({
    default: 100,
    width: 0, 
    height: 0,
    imageState: true,
  });

  useLayoutEffect(() => {
    axios.get("https://api.db-ip.com/v2/free/self")
      .then(result => {
        setIP(result.data.ipAddress)
      })
    dispatch(getEventAnonyByIdAction(eventanonid));
  }, []);
  const OpenModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  /*
  http://yepp-api.azurewebsites.net/api/Paymes/Pay request body içindeki clientIp'ı çekme
  const getIPAdresss = async () => {
      const res = await axios.get('adres')
     
      setIP(res.data.IPv4)
    } */

  useEffect(() => {
    if (eventanony?.image !== "") {
      //setimageClass("");
    }
    var img = new Image();
    img.addEventListener("load", function () {
      setimageSize((value) => ({
        ...value,
        ...imageSizeFonk(this.width, this.height),
      }));
    });
    if (eventanony?.image) {
      img.src = eventanony.image;
    }
  }, [eventanony]);

  useEffect(() => {
    console.log(eventanony.entities)
    if (eventanony.entities) {
      setstatePicture(eventanony.entities.image);
    }
  }, [eventanony.entities]);

  return (
    <Card className="cardcontainer">
      <CardHeader> </CardHeader>

      <CardBody>

        <Formik>
          {() => (
            <>
              <Form className="newform">
                {/*image*/}
                <div className="form-group newgroup ">
                  <div className="col-lg-4 col-xl-4">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div />
                      <img
                        className="img-responsive"
                        src={statePicture === "" ? "" : statePicture}
                        width={imageSize.default}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/*title*/}
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>
                      {intl.formatMessage({ id: "new_product_title" })}
                    </label>
                    <input
                      type="text"
                      value={eventanony?.entities?.title}
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
                {/* date*/}
                <label>{intl.formatMessage({ id: "new_product_date" })}</label>
                <div className="form-group row">
                  {/*   <Saat/> */}
                  <div className="col-lg-12">
                    <input
                      type="text"
                      disabled
                      value={eventanony?.entities?.endDate}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* organization list db'den gelecek*/}
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>
                      {intl.formatMessage({ id: "new_product_org" })}
                    </label>
                    {eventanony?.entities && (
                      <input
                        type="text"
                        disabled
                        value={intl.formatMessage({
                          id: eventanony?.entities?.categoryId,
                        })}
                        className="form-control"
                      />
                    )}
                  </div>
                </div>
                {/* status  db'den gelecek*/}
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>
                      {intl.formatMessage({ id: "event_list_status" })}
                    </label>
                    {eventanony?.entities && (
                      <input
                        type="text"
                        disabled
                        value={intl.formatMessage({
                          id: eventanony?.entities?.statusId,
                        })}
                        className="form-control"
                      />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>
                      Toplam Fiyat
                    </label>
                    {eventanony?.entities && (
                      <input
                        type="text"
                        disabled
                        value={eventanony?.entities?.totalBalance}
                        className="form-control"
                      />
                    )}
                  </div>
                </div>
                {/*description*/}
                <div className="form-group">
                  <label>
                    {intl.formatMessage({ id: "new_product_description" })}
                  </label>
                  <textarea
                    name=""
                    disabled
                    id=""
                    defaultValue={eventanony?.entities?.description}
                    className="form-control"
                    cols="30"
                    rows="6"
                  ></textarea>
                </div>
              </Form>
              <div className="column mt-15 display-flex align-items-center justify-content-center">
                <TableWidget />
                <Modal1 id={eventanonid} baslik={eventanony?.entities?.title} />
                <div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </CardBody>
      <div style={{ color: "#b22f91" }}>
      
        {
          
          !eventanony?.entities?.payment ?
            <Container ipAddress={ip} eventId={eventanonid} title={eventanony?.entities?.title} />
            : (
              
              <div className="w-100 d-flex justify-content-center align-items-center border-dark border rounded" style={{ height: "150px", color: "#b22f91", fontSize: "24px" }}>
                <img
                        className="img-responsive"
                        src={logo} alt="logo" 
                        width={imageSize.default}
                        alt=""
                      />
                Ödeme Tamamlanmıştır
              </div>
            )
        }
      </div>
    </Card>
  );
}

export default injectIntl(Anonim);