import React, { useState, useLayoutEffect, useEffect,useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { Input, Select, } from "../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Saat from "./Saat";
import { Link } from "react-router-dom"
import "./gift.css";

import {
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom"

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import "./anonim.css"




function UpdateGift({intl}) {


    
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();

  const [selectedDefaultDate, setSelectedDefaultDate] = useState();
 const [setPriority, setSelectedPriority] = useState();
  const [statePicture, setstatePicture] = useState("")
  const { eventid } = useParams()
  const btnRef = useRef();

    // Validation schema
  
  const GiftAddSchema = Yup.object().shape({
    name: Yup.string()
      .min(6, "Min 6 karakter olmalı")
      .max(30, "Max 30 karakter olmalı")
      .required("Bu alan boş geçilemez"),
    description: Yup.string()
      .min(25,"Min 25 karakter")
      .max(500, "Max  500 karakter")
      .required("Boş geçilemez"),
    link: Yup.string()
      .required("Boş geçilemez"),
    quantity: Yup.number()
      .required("Boş geçilemez"),
    priority: Yup.number()
      .required("Boş geçilemez")
      
  });



  const handleDateChange = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + '/' + dd;//yıl ay gün
    setSelectedDate(today);
    setSelectedDefaultDate(date)
  };


 
  const handleChangePicture = (e) => {
    if (e.target.files.length === 0) {
      setstatePicture("")
      return
    }
    [...e.target.files].map(file => {
      const reader = new FileReader()
      reader.addEventListener("load", function () {
        setstatePicture(this.result)
      })
      reader.readAsDataURL(file)
    })
  }
  const removePicture = () => {
    setstatePicture("")
  }

const priority = useSelector(state => state.priorityReducer)
  const gift={
      eventid:eventid,
      name:"",
      image:"",
      description:"",
      link:"",
      quantity:"",
      priority:"",
      price:0
     
  }
  
  return (
    <Card className="cardcontainer" >

      <CardBody>


        <Formik  initialValues={gift}
          validationSchema={GiftAddSchema}
          enableReinitialize={true}
          onSubmit={() =>alert("burasııııı") }
        
        
        >
          {({handleSubmit}) => (
            <>
              <Form >
              <CardHeaderToolbar>
                    <div className="form-group row card-toolbar" >
                      <div className="col-lg-3"></div>
                      <div className="col-lg-5"></div>
                      <div className="col-lg-3">
                        <Link
                          to="/event/eventlist"
                          className="btn btn-light flex-column "
                          style={{ color: "#fff", backgroundColor: "#b22f91" }}
                        >
                          <i className="fa fa-arrow-left"></i>
                       GERİ
                        </Link>

                        <button
                          type="submit"
                          className="btn btn-light"
                          style={{ color: "#fff", backgroundColor: "#b22f91" }}
                          variant="contained"
                         


                        >
                          <i class="fas fa-check"></i>
                        KAYDET
                        </button>
                      </div>
                    </div>
                  </CardHeaderToolbar>
             

                {/*image*/}
                <div className="form-group image_area" >
                <div 
         
         className="image-input image-input-outline "
                  id="kt_profile_avatar"
                >
                  <div
                    className="image-input-wrapper"
                    style={{
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${statePicture === "" ? " " : statePicture}`,
                    }}
                  />
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <input
                      onChange={handleChangePicture}
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                    // {...formik.getFieldProps("pic")}
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Cancel avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title=""
                    onClick={removePicture}
                    data-original-title="Remove avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>

              </div>
              <div className="form-group form_area">
                  <div className="col-lg-6">
                    <label>Link</label>

                    <Field
                    component={Input}
                    name="link"
                    placeholder="Link"
                  />
                  </div>
                </div>



                {/*Name*/}
                <div className="form-group form_area">
                  <div className="col-lg-6">
                    <label>Name</label>
                    <Field
                    component={Input}
                    name="name"
                    placeholder="Name"
                  />

                  </div>
                </div>

                {/* description*/}
              
                <div className="form-group form_area">
                  
                  <div className="col-lg-6">
                   
                  <label>Description</label>
                  <Field
                    component={Input}
                    name="description"
                    placeholder="Description"
                  />



                  </div>


                </div>
                 {/* price*/}


                 
                <div className="form-group form_area">
                  
                  <div className="col-lg-6">
                 
                  <label>Price</label>
                  <Field
                    component={Input}
                    name="price"
                    placeholder="Price"
                  />

 
                  
                  </div>


                </div>
                

               <div className="form-group form_area">
                  <div className="col-lg-6">


                   
                      <label>Quantity</label>
                      <Field
                    component={Input}
                    name="quantity"
                    placeholder="Quantity"
                  />
                   

                  </div>
                </div>



                {/*description*/}
                <div className="form-group form_area">
                <div className="col-lg-6">
                  <label>Priority</label>
                  <Field

                
                name="quantity"
               as="select"
               className="inputwidth"
               value={setPriority}
                
                >


{
                    priority && priority.map(pri =>
                        <option key={pri.id} value={pri.name} >
                         {pri.rate}
                        </option>
                      )
                    }
  
</Field>
                </div>
                </div>
               
               
              </Form>

             
               
              <div className="column mt-15 text-align-center justify-content-center ml-20 ">
               </div>
               
 </>
          )}
        </Formik>

      </CardBody>
    </Card>
  );
}



export default UpdateGift;
