import React, { Component, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

const Logout = (props) => {

  useEffect(() => {
    props.logout()
  }, [])

  return <div>
    
  </div>
}

export default connect(
  ({ auth }) => {
    return { hasAuthToken: auth.authToken }
  }, auth.actions)(Logout);
