// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { Input, Select, } from "../../../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import { Button } from '@material-ui/core';
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import "react-datepicker/dist/react-datepicker.css";
import { AdvanceTablesWidget4 } from '../../../../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget4'
import { MixedWidget14 } from '../../../../../../_metronic/_partials/widgets/mixed/MixedWidget14'
import "./custom.css"

import {
  AVAILABLE_COLORS,
  AVAILABLE_MANUFACTURES,
  ProductStatusTitles,
  ProductConditionTitles,
} from "../ProductsUIHelpers";
import Saat from "./Saat";
import { StatusColumnFormatter } from "../../customers/customers-table/column-formatters";
import { useRef } from "react";
import { useEffect } from "react";
import { imageSizeFonk } from "./imageSize";
import { id } from "date-fns/locale";



function ProductEditForm({
  product,
  btnRef,
  saveProduct,
  intl
}) {

  const [selectedDate, setSelectedDate] = useState();
  const [selectedDefaultDate, setSelectedDefaultDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [imageClass, setimageClass] = useState("image-input-wrapper")
  const [statePicture, setstatePicture] = useState("")

  const [imageSize, setimageSize] = useState({
    width: 0,
    height: 0,
  })
  const inputFile = useRef()


  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value)
  }

  const handleCategoriesChange = (e) => {
    setSelectedOrganization(e.target.value)
  }

  const categories = useSelector(state => state.categories)
  const status = useSelector(state => state.status)

  // Validation schema
  const ProductEditSchema = Yup.object().shape({
    title: Yup.string()
      .min(4, intl.formatMessage({
        id: "new_product_titleMin",
      }))
      .max(30, intl.formatMessage({
        id: "new_product_titleMax",
      }))
      .required(intl.formatMessage({
        id: "alert_empty",
      })),
    description: Yup.string()
      .min(25, intl.formatMessage({
        id: "event_detail_decMin",
      }))
      .max(500, intl.formatMessage({
        id: "event_detail_decMax",
      }))
      .required(intl.formatMessage({
        id: "alert_empty",
      })),
    endDate: Yup.string()
      .required(intl.formatMessage({
        id: "alert_empty",
      }))
      .nullable(),
  });

  const handleDateChange = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + '/' + dd;//yıl ay gün
    setSelectedDate(today);
    setSelectedDefaultDate(date)
  };


  const removePicture = () => {
    setstatePicture("")
    inputFile.current.value = []
    setimageSize({
      width: 0,
      height: 0
    })
  }



  const handleChangePicture = (e) => {

    if (e.target.files.length === 0) {
      inputFile.current.value = []
      setstatePicture("")
      setimageSize({
        width: 0,
        height: 0
      })
      return
    }
    [...e.target.files].map(file => {
      let img = new Image();
      const reader = new FileReader()
      reader.addEventListener("load", function () {
        setstatePicture(this.result)
        img.onload = function () {
          setimageSize(value => ({ ...value, ...imageSizeFonk(this.width, this.height) }))
        };
        img.src = this.result;
      })
      reader.readAsDataURL(file)
    })
  }


  const { userid } = useSelector(
    ({ auth }) => ({
      userid: auth.user.id
    }),
    shallowEqual
  );


  const reftitle = useRef()


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        validationSchema={ProductEditSchema}
        onSubmit={(values, { resetForm }) => {
          values.categoryId = selectedOrganization || categories[0]?.id
          values.endDate = selectedDate.replace(/\//g, "-")
          values.statusId = selectedStatus || status[0]?.id
          values.userid = userid
          values.totalbalance = 0
          values.image = statePicture
          let resetAction = {
            resetForm,
            setSelectedOrganization,
            setSelectedStatus,
            setSelectedDate,
            setstatePicture,
            defaultOrganization: categories[0]?.id,
            defaultStatus: status[0]?.id
          }
          saveProduct(values, resetAction);
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <Form className="form form-label-right  formik-form-container" >

              {/*image*/}
              <div className="form-group img-responsive file-image-container" style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "auto", marginBottom: 20 }}>
                <div
                  className="image-input image-input-outline image"
                  id="kt_profile_avatar"
                  style={{
                    width: imageSize.width === 0 ? "80%" : imageSize.width + "%",
                    height: imageSize.height === 0 ? "70%" : imageSize.height + "%",
                  }}

                >
                  <div
                    className="image-input-wrapper"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundSize: "100% 100%",
                      backgroundImage: `url(${statePicture === "" ? " " : statePicture}`,
                    }}
                  />
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <input
                      ref={inputFile}
                      onChange={handleChangePicture}
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                    // {...formik.getFieldProps("pic")}
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Cancel avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title=""
                    onClick={removePicture}
                    data-original-title="Remove avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>

              </div>



              {/*title*/}
              <div className="form-group form_input_area ">
                <div className="col-lg-6" ref={reftitle}>
                  <label>{intl.formatMessage({ id: "new_product_title" })}</label>
                  <Field
                    component={Input}
                    name="title"
                    placeholder={intl.formatMessage({ id: "new_product_title" })}
                  />
                </div>
              </div>

              {/* date*/}

              <div className="form-group form_input_area ">

                <div className="col-lg-6" >
                  <label>{intl.formatMessage({ id: "new_product_date" })}</label>

                  <Field
                    name="endDate"
                    render={({ field, form, form: { touched, errors } }) => (
                      <div>
                        <Saat
                          defaultDate={selectedDefaultDate}
                          handleDateChange={handleDateChange}
                          type="text"
                          inputName="endDate"
                          fielder={field}
                          former={form}
                        />
                        {touched[field.name] &&
                          errors[field.name] && <div className="error text-danger">{errors[field.name]}</div>}
                      </div>
                    )}
                  />


                </div>


              </div>


              {/* organization list db'den gelecek*/}
              <div className="form-group form_input_area ">
                <div className="col-lg-6">
                  <label>{intl.formatMessage({ id: "new_product_org" })}</label>

                  <Field
                    name="category_id"
                    as="select"
                    className="inputwidth"
                    value={selectedOrganization}
                    onChange={handleCategoriesChange}
                  >

                    {
                      categories.map(category =>
                        <option key={category.id} value={category.id} >
                          {
                            intl.formatMessage({
                              id: category.id,
                            })
                          }
                        </option>
                      )
                    }
                  </Field>
                </div>
              </div>

              {/* status  db'den gelecek*/}
              <div className="form-group form_input_area ">
                <div className="col-lg-6">
                  <label>{intl.formatMessage({ id: "event_list_status" })}</label>
                  <Field

                    onChange={handleStatusChange}
                    name="status"
                    as="select"
                    className="inputwidth"
                    value={selectedStatus}
                  >

                    {
                      status.map(stat =>
                        <option key={stat.id} value={stat.id} >
                          {
                            intl.formatMessage({
                              id: stat.id,
                            })
                          }
                        </option>
                      )
                    }
                  </Field>
                </div>
              </div>

              {/*description*/}
              <div className="form-group form_input_area ">
                <div className="col-lg-6">
                  <label>{intl.formatMessage({ id: "new_product_description" })}</label>
                  <Field
                    name="description"
                    render={({ field, form: { touched, errors } }) => (
                      <div>
                        <textarea className="inputwidth" rows="5" {...field} type="text" placeholder={intl.formatMessage({ id: "new_product_description" })} ></textarea>
                        {touched[field.name] &&
                          errors[field.name] && <div className="error text-danger">{errors[field.name]}</div>}
                      </div>
                    )}
                  />

                </div>

              </div>





              <Button type="submit" hidden={true}
                style={{ color: "#fff", backgroundColor: "#b22f91" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()} variant="contained">{intl.formatMessage({ id: "create" })}</Button>




            </Form>


            {/* <div className="row mt-15 text-align-center justify-content-center"><AdvanceTablesWidget4 /></div> */}













          </>
        )}
      </Formik>
    </>
  );
}



export default injectIntl(ProductEditForm)
