import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { injectIntl } from "react-intl";
import DatePicker from "react-datepicker";
import "./custom.css";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Share from "./ShareSocial/Share";
import { Button } from "@material-ui/core";
import { AdvanceTablesWidget4 } from "../../../../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget4";
import { Link } from "react-router-dom";
import Modal1 from "./ShareSocial/Modal";
import { useIntl, FormattedMessage } from "react-intl";

//import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import "./custom.css";
import { useHistory, useParams } from "react-router";
import Saat from "./Saat";
import { updateProduct } from "../../../_redux/products/productsActions";
import { imageSizeFonk } from "./imageSize";
import PopupModal from "../../../../UserProfile/PopupModal";

function ProductDetail() {
  const { eventid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const categories = useSelector((state) => state.categories);
  const status = useSelector((state) => state.status);
  const events = useSelector((state) =>
    state?.products?.entities?.find((event) => event.id === eventid)
  );
  const { userid } = useSelector(
    ({ auth }) => ({
      userid: auth.user.id,
    }),
    shallowEqual
  );

  const [selectedDate, setSelectedDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedDefaultDate, setSelectedDefaultDate] = useState();
  const [statePicture, setstatePicture] = useState("");
  const [imageSize, setimageSize] = useState({
    width: 0,
    height: 0,
  });
  const [imageClass, setimageClass] = useState("image-input-wrapper");
  const [alertField, setAlertField] = useState({
    title: "",
    text: "",
    show: false,
    color: "#23ba47",
  });

  useEffect(() => {
    setSelectedStatus(events?.statusId);
    setSelectedOrganization(events?.categoryId);
    setSelectedDefaultDate(events?.endDate);
    setSelectedDate(events?.endDate);
    setstatePicture(events?.image);
  }, [events]);

  const saveProduct = (values) => {
    dispatch(updateProduct(values, setAlertField, intl));
  };

  const initialValues = {
    image: "",
    title: events?.title,
    description: events?.description,
    categoryId: "",
    endDate: events?.endDate,
    statusId: events?.statusId,
  };

  // Validation schema
  const ProductEditSchema = Yup.object().shape({
    title: Yup.string()
      .min(
        4,
        intl.formatMessage({
          id: "new_product_titleMin",
        })
      )
      .max(
        30,
        intl.formatMessage({
          id: "new_product_titleMax",
        })
      )
      .required(
        intl.formatMessage({
          id: "alert_empty",
        })
      ),
    description: Yup.string()
      .min(
        25,
        intl.formatMessage({
          id: "event_detail_decMin",
        })
      )
      .max(
        500,
        intl.formatMessage({
          id: "event_detail_decMax",
        })
      )
      .required(
        intl.formatMessage({
          id: "alert_empty",
        })
      ),
    endDate: Yup.string()
      .required(
        intl.formatMessage({
          id: "alert_empty",
        })
      )
      .nullable(),
  });

  const inputFile = useRef();

  const removePicture = () => {
    setstatePicture("");
    inputFile.current.value = [];
    setimageSize({
      width: 0,
      height: 0,
    });
  };

  useEffect(() => {
    if (events?.image !== "") {
      setimageClass("");
    }
    var img = new Image();
    img.addEventListener("load", function() {
      setimageSize((value) => ({
        ...value,
        ...imageSizeFonk(this.width, this.height),
      }));
    });
    if (events?.image) {
      img.src = events.image;
    }
  }, [events]);

  const handleChangePicture = (e) => {
    if (e.target.files.length === 0) {
      setstatePicture("");
      inputFile.current.value = [];
      setimageSize({
        width: 0,
        height: 0,
      });
      return;
    }
    [...e.target.files].map((file) => {
      let img = new Image();
      const reader = new FileReader();
      reader.addEventListener("load", function() {
        setstatePicture(this.result);
        img.onload = function() {
          setimageSize((value) => ({
            ...value,
            ...imageSizeFonk(this.width, this.height),
          }));
        };
        img.src = this.result;
      });
      reader.readAsDataURL(file);
    });
  };
  const handleDateChange = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd; //yıl ay gün
    setSelectedDate(today);
    setSelectedDefaultDate(date);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleCategoriesChange = (e) => {
    setSelectedOrganization(e.target.value);
  };

  const reftitle = useRef();

  return (
    <>
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={ProductEditSchema}
            onSubmit={(values) => {
              values.categoryId = selectedOrganization;
              values.endDate = selectedDate.replace(/\//g, "-");
              values.statusId = selectedStatus;
              values.userid = userid;
              values.id = eventid;
              values.totalbalance = 0;
              values.image = statePicture;

              saveProduct(values);
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Form className="form form-label-right form-detail formik-form-container  ">
                  <PopupModal
                    {...alertField}
                    show={alertField.show}
                    toPushPage={() => history.push("/event/eventlist")}
                    onHide={() =>
                      setAlertField((value) => ({ ...value, show: false }))
                    }
                  />
                  <CardHeaderToolbar>
                    <div className="form-group row card-toolbar">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4">
                        <Link
                          to="/event/eventlist"
                          className="btn btn-light flex-column "
                          style={{ color: "#fff", backgroundColor: "#b22f91" }}
                        >
                          <i className="fa fa-arrow-left"></i>
                          {intl.formatMessage({ id: "back" })}
                        </Link>

                        <button
                          type="submit"
                          className="btn btn-light"
                          style={{ color: "#fff", backgroundColor: "#b22f91" }}
                          variant="contained"
                        >
                          <i class="fas fa-check"></i>
                          {intl.formatMessage({ id: "save" })}
                        </button>
                      </div>
                    </div>
                  </CardHeaderToolbar>

                  {/*image*/}

                  <div
                    className="form-group img-responsive file-image-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                      marginBottom: 20,
                    }}
                  >
                    <div
                      className="image-input image-input-outline image"
                      id="kt_profile_avatar"
                      style={{
                        width:
                          imageSize.width === 0 ? "80%" : imageSize.width + "%",
                        height:
                          imageSize.height === 0
                            ? "70%"
                            : imageSize.height + "%",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundSize: "100% 100%",
                          backgroundImage: `url(${
                            statePicture === "" ? " " : statePicture
                          }`,
                        }}
                      />

                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted"></i>
                        <input
                          ref={inputFile}
                          onChange={handleChangePicture}
                          type="file"
                          name="image"
                          accept=".png, .jpg, .jpeg"
                          // {...formik.getFieldProps("pic")}
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title=""
                        onClick={removePicture}
                        data-original-title="Remove avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                  </div>

                  {/*title*/}
                  <div className="form-group form_area">
                    <div className="col-lg-6" ref={reftitle}>
                      <label>
                        {intl.formatMessage({ id: "new_product_title" })}
                      </label>
                      <Field
                        component={Input}
                        name="title"
                        placeholder={intl.formatMessage({
                          id: "new_product_title",
                        })}
                      />
                    </div>
                  </div>

                  {/* date*/}

                  <div className="form-group  form_area">
                    {/*   <Saat/> */}
                    <div className="col-lg-6">
                      <label>
                        {intl.formatMessage({ id: "new_product_date" })}
                      </label>

                      <Field
                        name="endDate"
                        render={({
                          field,
                          form,
                          form: { touched, errors },
                        }) => (
                          <div>
                            <Saat
                              defaultDate={selectedDefaultDate}
                              handleDateChange={handleDateChange}
                              type="text"
                              inputName="endDate"
                              fielder={field}
                              former={form}
                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error text-danger">
                                {errors[field.name]}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  {/* organization list db'den gelecek*/}
                  <div className="form-group form_area ">
                    <div className="col-lg-6">
                      <label>
                        {intl.formatMessage({ id: "new_product_org" })}
                      </label>

                      <Field
                        name="categoryId"
                        as="select"
                        className="inputwidth"
                        value={selectedOrganization}
                        onChange={handleCategoriesChange}
                      >
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {intl.formatMessage({
                              id: category.id,
                            })}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  {/* status  db'den gelecek*/}
                  <div className="form-group form_area ">
                    <div className="col-lg-6">
                      <label>
                        {intl.formatMessage({ id: "event_list_status" })}
                      </label>
                      <Field
                        onChange={handleStatusChange}
                        name="statusId"
                        as="select"
                        className="inputwidth"
                        value={selectedStatus}
                      >
                        {status.map((stat) => (
                          <option key={stat.id} value={stat.id}>
                            {intl.formatMessage({
                              id: stat.id,
                            })}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  {/*description*/}
                  <div className="form-group  form_area ">
                    <div className="col-lg-6">
                      <label>
                        {intl.formatMessage({ id: "new_product_description" })}
                      </label>

                      <Field
                        name="description"
                        render={({ field, form: { touched, errors } }) => (
                          <div>
                            <textarea
                              className="inputwidth"
                              rows="5"
                              {...field}
                              type="text"
                              placeholder={intl.formatMessage({
                                id: "new_product_description",
                              })}
                            ></textarea>
                            {touched[field.name] && errors[field.name] && (
                              <div className="error text-danger">
                                {errors[field.name]}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Form>
                <label
                  style={{ marginLeft: "80%", cursor: "pointer" }}
                  htmlFor="contained-button-file"
                >
                  <Button
                    style={{
                      backgroundColor: "#b22f91",
                      color: "white",
                      top: 55,
                    }}
                    onClick={() => history.push("/gift/addgift/" + eventid)}
                    variant="contained"
                    component="span"
                  >
                    {intl.formatMessage({ id: "add_gift" })}
                  </Button>
                </label>
                <div className="column mt-15   text-align-center justify-content-center">
                  <AdvanceTablesWidget4 />
                  <Modal1 id={eventid} baslik={initialValues.title} />
                </div>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(ProductDetail);
