import { GET_STATUS_SUCCESS, GET_STATUS_ERROR } from "../actiontype"
import axios from "axios"
import { baseUrlApi } from "../BaseURL"



const GET_STATUS = baseUrlApi + "EventStatus/Getlist"

export const getStatusAction = () =>
    dispatch => {
        axios.get(GET_STATUS)
            .then(result => {
                dispatch({ type: GET_STATUS_SUCCESS, payload: result.data })
            })
            .catch(err => {
                dispatch({ type: GET_STATUS_ERROR, payload: "status'e ulaşılamadı" })
            })
    }


