import React from "react";
import { useSubheader } from "../../_metronic/layout";
import Portfolio from './Portfolio'
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import '../../yepp_media/mainpage_assets/css/bootstrap.min.css'
import '../../yepp_media/mainpage_assets/css/agency.min.css'
import { FormattedMessage } from "react-intl";


import video from '../../yepp_media/videos/header_videos/header_video_1.mp4'
import { makeStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardActionArea } from '@material-ui/core';

import { CardContent } from '@material-ui/core';
import { CardMedia } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Footer } from '../../_metronic/layout/components/footer/Footer'


const useStyles = makeStyles({
  root: {
    maxWidth: 405,
  },
  media: {
    height: 160,
  },
});







export const MyPage = () => {
  //const suhbeader = useSubheader();
  //suhbeader.setTitle("My Custom title");
  const classes = useStyles();




  return (

    <div className="pagecontainer">


<div className="slider-video">
      <video autoPlay loop muted controls poster="BSCA_Logo.png">
                    <source  src={video} type="video/mp4" />
                </video>
      </div>
     




      <Portfolio></Portfolio>


      <section className="page-section" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase"><FormattedMessage id="mypage_do" /></h2>
              <h3 className="section-subheading text-muted"><FormattedMessage id="mypage_organization" /></h3>
            </div>
          </div>
          <div className="row text-center">

            <div className="col-md-3">
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../yepp_media/event_images/organization_calendar.png")}
                    title="Calendar"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <FormattedMessage id="mypage_box1.1" />
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <FormattedMessage id="mypage_box1.2" />
                    </Typography>
                  </CardContent>
                </CardActionArea>

              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../yepp_media/event_images//organization_home.png")}
                    title="Home"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <FormattedMessage id="mypage_box2.1" />
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <FormattedMessage id="mypage_box2.2" />
                    </Typography>
                  </CardContent>
                </CardActionArea>

              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../yepp_media/event_images/organization_wedding.png")}
                    title="Wedding"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <FormattedMessage id="mypage_box3.1" />
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <FormattedMessage id="mypage_box3.2" />
                    </Typography>
                  </CardContent>
                </CardActionArea>

              </Card>
            </div>
            <div className="col-md-3">
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../yepp_media/event_images/organization_baby.png")}
                    title="Baby"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <FormattedMessage id="mypage_box4.1" />
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      <FormattedMessage id="mypage_box4.2" />
                    </Typography>
                  </CardContent>
                </CardActionArea>

              </Card>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase"><FormattedMessage id="mypage_yepp_work?" /></h2>
              <h3 className="section-subheading text-muted"><FormattedMessage id="mypage_create_share" /></h3>
            </div>
          </div>



          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">


                <li>
                  <div className="timeline-image  last_image">
                    <img className="img-fluid  how_does_image4"  src={require("../../yepp_media/how_does_images_white/1_login.png")} />

                  </div>


                  <div className="timeline-panel">
                    <div className="timeline-heading">

                      <h4 className="subheading"><FormattedMessage id="mypage_create_profile" /></h4>

                    </div>

                  </div>
                </li>

                <li className="timeline-inverted">
                  <div className="timeline-image last_image">
                    <img className="img-fluid how_does_image4" src={require("../../yepp_media/how_does_images_white/2_event_planning.png")} />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">

                      <h4 className="subheading"><FormattedMessage id="mypage_organizations_plan" /></h4>
                    </div>

                  </div>
                </li>


                <li>
                  <div className="timeline-image last_image">
                    <img className="img-fluid how_does_image4 " src={require("../../yepp_media/how_does_images_white/3_gift.png")} />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">

                      <h4 className="subheading"><FormattedMessage id="mypage_wishes_list" /></h4>
                    </div>

                  </div>
                </li>


                <li className="timeline-inverted">
                  <div className="timeline-image last_image">
                    <img className="img-fluid  how_does_image4 " src={require("../../yepp_media/how_does_images_white/4_sharing.png")} />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">

                      <h4 className="subheading"><FormattedMessage id="mypage_share" /></h4>
                    </div>

                  </div>

                </li>



                <li className="timeline-inverted">
                  <div className="timeline-image last_image1">
                    <h4><FormattedMessage id="mypage_create_box1" />
                      <br /><FormattedMessage id="mypage_create_box2" />
                      <br /><FormattedMessage id="mypage_create_box3" /></h4>
                  </div>
                </li>



              </ul>
            </div>
          </div>




        </div >
      </section >

      <Footer></Footer>
    </div >
  );
};
