import React, { useState } from 'react';
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { StateChangeTypes } from "downshift";
import { updateGiftQuantityAction, updateGiftQuantityActionInc  } from "../../../../app/modules/Redux/actions/GiftAction";

export  default  function QuantityPicker(props) {
  const [disableDec,setDisableDec]=useState(true);
  const [disableInc,setdisableInc]=useState(false)
  

  
const  dispatch = useDispatch()

  const  increment=()=> {
    dispatch(updateGiftQuantityActionInc(props.gift))
    }

 
 const   decrement=()=> {
  
      dispatch(updateGiftQuantityAction( props.gift))
  
   
     
     
  }

 return (
      <span className="quantity-picker">
        <button className={`${disableDec ? 'mod-disable ' : ''}quantity-modifier modifier-left`}  onClick={decrement}>&ndash;</button>
        <input className="quantity-display" type="text" value={props.gift.quantity} readOnly />
        <button className={`${disableInc ? 'mod-disable ' : ''}quantity-modifier modifier-right`} onClick={increment}>&#xff0b;</button>
      </span>
    );
  }
