import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: "AUTH.VALIDATION.WRONG.EMAIL.FORMAT",
      }))
      .min(3,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
        }))
      .max(50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
        }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [Alert, setAlert] = useState(null)

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => {
          setAlert(true)
          //setIsRequested(true)
        })
        .catch((result) => {
          setAlert(false)
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              {intl.formatMessage({
                id: "AUTH.FORGOT.TITLE",
              })}</h3>
            <div className="text-muted font-weight-bold">

              {intl.formatMessage({
                id: "AUTH.FORGOT.DESC",
              })}
            </div>
          </div>




          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {/* {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )} */}
            {
              Alert !== null && (Alert
                ? <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                  <div className="alert-text font-weight-bold">
                  {intl.formatMessage({
                      id: "alert_new_pass"
                    })}
                  </div>
                </div>
                : <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {intl.formatMessage({
                      id: "alert_pass_registered"
                    })}</div>
                </div>
              )
            }
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >

                {intl.formatMessage({
                  id: "AUTH.GENERAL.SUBMIT_BUTTON",
                })}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {intl.formatMessage({
                    id: "AUTH.GENERAL.BACK_BUTTON",
                  })}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
