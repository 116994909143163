import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { Input, Select, } from "../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

import "./gift.css";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom"
import { Button } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Switch,
    useLocation
} from "react-router-dom"
import { useIntl, FormattedMessage } from "react-intl";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import "./anonim.css"
import { PriorityHighOutlined } from "@material-ui/icons";
import { imageSizeFonk } from "./imageSize";
import axios from "axios";
import { baseUrlScraping } from "../../../../Redux/BaseURL";
import PopupModal from "../../../../UserProfile/PopupModal";
import { createGiftAction, getGifMarketList, getGiftDetailById, updateGiftAction } from "../../../../Redux/actions/GiftAction";




function GiftDetail() {

    const intl = useIntl();


    const [imageSize, setimageSize] = useState({
        width: 0,
        height: 0,
    })
    const [alertField, setAlertField] = useState({
        title: "",
        text: "",
        show: false,
        color: "#23ba47"
    })

    const [regexState, setregexState] = useState(new RegExp())
    const { giftid } = useParams()

    const giftDetail = useSelector(state => state.gifts.selectGift)

    useLayoutEffect(() => {
        dispatch(getGiftDetailById(giftid))
    }, [])

    const updateGift = (values) => {
        dispatch(updateGiftAction(values, setAlertField, intl))
    }

    const [linkState, setlinkState] = useState({
        imagePath: "",
        price: ""
    })

    useEffect(() => {
        let img = new Image();
        img.onload = function () {
            setimageSize(value => ({ ...value, ...imageSizeFonk(this.width, this.height) }))
        };
        img.src = giftDetail.image;
        setlinkState({
            imagePath: giftDetail.image,
            price: giftDetail.price + ""
        })
        setSelectedPriority(giftDetail.priorityId)
    }, [giftDetail])




    const dispatch = useDispatch()
    const marketList = useSelector(state => state.gifts.marketList)

    // Validation schema
    useEffect(() => {
        var patternString = ""
        marketList.forEach((market, index) => {
            patternString += "^www." + market.webSite + "\/|^https:\/\/www." + market.webSite + "\/|^" + market.webSite + "\/|^www." + market.webSite + ".tr\/|^https:\/\/www." + market.webSite + ".tr\/|^" + market.webSite + ".tr\/"
            if (index !== marketList.length - 1) {
                patternString += "|"
            }
        })
        setregexState(RegExp(patternString, "g"))
    }, [marketList])

    const GiftAddSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, intl.formatMessage({ id: "gift_name_alert_min" }))
            .max(30, intl.formatMessage({ id: "gift_name_alert_max" }))
            .required(intl.formatMessage({ id: "alert_empty" })),
        description: Yup.string()
            .min(10, intl.formatMessage({ id: "event_detail_decMin" }))
            .max(500, intl.formatMessage({ id: "event_detail_decMax" }))
            .required(intl.formatMessage({ id: "alert_empty" })),
        link: Yup.string()
            .required(intl.formatMessage({ id: "alert_empty" }))
            .matches(regexState, "bu web sitesinin ürünleri eklenememektedir"),
        quantity: Yup.number()
            .required(intl.formatMessage({ id: "alert_empty" }))
            .min(1, intl.formatMessage({ id: "min_one" })),
        price: Yup.string()
            .required("bu değer siteden çekilecektir")
    });


    useLayoutEffect(() => {
        dispatch(getGifMarketList())
    }, [])




    useEffect(() => {

    }, [marketList])

    useEffect(() => {
    }, [linkState])

    const handleLink = (e, resetForm, getFieldHelpers) => {
        setlinkState({ imagePath: "", price: "", buttonAndInput: false })
        const option = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        };
        //const linkRegex1 = /^www.gittigidiyor.com\/|^https:\/\/www.gittigidiyor.com\/|^gittigidiyor.com\/|^www.gittigidiyor.com.tr\/|^https:\/\/www.gittigidiyor.com.tr\/|^gittigidiyor.com.tr\//g

        var patternString = ""
        marketList.forEach((market, index) => {
            patternString += "^www." + market.webSite + "\/|^https:\/\/www." + market.webSite + "\/|^" + market.webSite + "\/|^www." + market.webSite + ".tr\/|^https:\/\/www." + market.webSite + ".tr\/|^" + market.webSite + ".tr\/"
            if (index !== marketList.length - 1) {
                patternString += "|"
            }
        })
        const linkPattern = new RegExp(patternString, "g");

        if (linkPattern.test(e.target.value)) {
            setlinkState(value => ({ ...value, imagePath: require('../../../../../../yepp_media/loading/loading1.gif'), buttonAndInput: true }))
            axios.post(baseUrlScraping, {
                link: e.target.value
            }, option)
                .then(result => {
                    if (result.status === 204)
                        setAlertField(value => ({ ...value, show: true, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "gift204", }), toPush: false }))
                    else {
                        let img = new Image();
                        img.onload = function () {
                            setimageSize(value => ({ ...value, ...imageSizeFonk(this.width, this.height) }))
                        };
                        img.src = result.data.ImgPath;
                        setlinkState({ imagePath: result.data.ImgPath, price: result.data.Price })
                        getFieldHelpers("price").setValue(result.data.Price + "")
                    }
                    setlinkState(value => ({ ...value, buttonAndInput: false }))
                })
                .catch(err => {
                    setlinkState({ imagePath: "", price: "", buttonAndInput: false })
                    if (err.response.status === 406)
                        setAlertField(value => ({ ...value, show: true, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "gift406", }), toPush: false }))

                    else if (err.response.status === 400)
                        setAlertField(value => ({ ...value, show: true, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "gift400", }), toPush: false }))

                    else if (err.response.status === 404)
                        setAlertField(value => ({ ...value, show: true, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "gift404", }), toPush: false }))

                    else if (err.response.status === 405)
                        setAlertField(value => ({ ...value, show: true, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "gift405", }), toPush: false }))
                })
        }
        // else {
        //   setAlertField(value => ({ ...value, show: true, title: "Başarısız", text: "Şuan bu web sitesinden ürün eklenememektedir", toPush: false }))
        //    resetForm({
        //      link: ""
        //    })
        // }
    }

    const history = useHistory()
    const priority = useSelector(state => state.priorityReducer)
    const [setPriority, setSelectedPriority] = useState();

    useEffect(() => {
        setSelectedPriority(priority[0]?.id)
    }, [priority])

    const gift = {
        name: giftDetail.name,
        description: giftDetail.description,
        quantity: giftDetail.quantity,
        link: giftDetail.link,
        price: giftDetail.price
    }

    return (
        <Card className="cardcontainer" >

            <CardBody>


                <Formik
                    initialValues={gift}
                    validationSchema={GiftAddSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        values.id = giftDetail.id
                        values.priority = setPriority
                        values.eventId = giftDetail.eventId
                        values.image = linkState.imagePath
                        let price = linkState.price.search("TL") !== -1 ? linkState.price.slice(0, -2) : linkState.price
                        price = price.replace(/,/g, ".")
                        values.price = Number.parseFloat(price.replace(".", ""))
                        values.quantity = values.quantity
                        updateGift(values)
                    }}
                    onReset={() => {

                    }}

                >
                    {({ handleSubmit, getFieldHelpers, resetForm, handleReset }) => (
                        <>
                            <Form >
                                <PopupModal
                                    {...alertField}
                                    show={alertField.show}
                                    toPushPage={alertField.toPush && (() => history.push("/event/eventdetail/" + giftDetail.eventId))}
                                    onHide={() => setAlertField(value => ({ ...value, show: false }))}
                                />
                                <CardHeaderToolbar>
                                    <div className="form-group row card-toolbar" >
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-3">
                                            <Link
                                                to={"/event/eventdetail/" + giftDetail.eventId}
                                                className="btn btn-light flex-column "
                                                style={{ color: "#fff", backgroundColor: "#b22f91" }}
                                            >
                                                <i className="fa fa-arrow-left"></i>
                                                {intl.formatMessage({ id: "back", })}
                                            </Link>

                                            <button
                                                disabled={linkState.buttonAndInput}
                                                type="submit"
                                                className="btn btn-light"
                                                style={{ color: "#fff", backgroundColor: "#b22f91" }}
                                                variant="contained"
                                            >
                                                <i class="fas fa-check"></i>
                                                {intl.formatMessage({ id: "save", })}
                                            </button>
                                        </div>
                                    </div>
                                </CardHeaderToolbar>


                                {/*image*/}
                                <div className="form-group img-responsive file-image-container" style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "auto", marginBottom: 20 }}>
                                    <div
                                        className="image-input image-input-outline image"
                                        id="kt_profile_avatar"
                                        style={{
                                            width: imageSize.width === 0 ? "80%" : imageSize.width + "%",
                                            height: imageSize.height === 0 ? "70%" : imageSize.height + "%",
                                        }}
                                    >
                                        <div
                                            className="image-input-wrapper"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                backgroundSize: "100% 100%",
                                                backgroundImage: `url(${linkState.imagePath !== "" ? linkState.imagePath : ""}`,
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="form-group  form_area">
                                    <div className="col-lg-6">
                                        <label>{intl.formatMessage({ id: "link", })}</label>

                                        <Field
                                            disabled={linkState.buttonAndInput}
                                            onChange={(e) => {
                                                getFieldHelpers("link").setValue(e.target.value)
                                                handleLink(e, resetForm, getFieldHelpers)
                                            }}
                                            component={Input}
                                            name="link"
                                            placeholder={intl.formatMessage({ id: "link", })}
                                        />
                                    </div>
                                </div>


                                {/*Name*/}
                                <div className="form-group form_area ">
                                    <div className="col-lg-6">
                                        <label>{intl.formatMessage({ id: "user_information_name", })}</label>
                                        <Field
                                            component={Input}
                                            name="name"
                                            placeholder={intl.formatMessage({ id: "user_information_name", })}
                                        />

                                    </div>
                                </div>

                                {/* description*/}

                                <div className="form-group form_area">

                                    <div className="col-lg-6">
                                        <label>{intl.formatMessage({ id: "event_list_description", })}</label>

                                        <Field
                                            component={Input}
                                            name="description"
                                            placeholder={intl.formatMessage({ id: "event_list_description", })}
                                        />


                                    </div>


                                </div>



                                <div className="form-group  form_area ">
                                    <div className="col-lg-6">
                                        <label>{intl.formatMessage({ id: "price", })}</label>

                                        <Field
                                            disabled="true"
                                            component={Input}
                                            name="price"
                                            //value={linkState.price}
                                            placeholder={intl.formatMessage({ id: "price", })}
                                        />
                                    </div>
                                </div>


                                <div className="form-group form_area ">
                                    <div className="col-lg-6">

                                        <div className="form-group">

                                            <label>{intl.formatMessage({ id: "quantity", })}</label>
                                            <Field
                                                min="1"
                                                type="number"
                                                component={Input}
                                                name="quantity"
                                                placeholder={intl.formatMessage({ id: "quantity", })}
                                            />
                                        </div>

                                    </div>
                                </div>




                                {/*priority*/}
                                <div className="form-group form_area">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>{intl.formatMessage({ id: "priority", })}</label>
                                            <Field


                                                name="priority"
                                                as="select"
                                                className="inputwidth"
                                                onChange={(e) => setSelectedPriority(e.target.value)}
                                                value={setPriority}
                                            >


                                                {
                                                    priority && priority.map(pri =>
                                                        <option key={pri.id} value={pri.id} >
                                                            {pri.rate}
                                                        </option>
                                                    )
                                                }

                                            </Field>
                                        </div>
                                    </div>
                                </div>



                            </Form>



                            <div className="column mt-15 text-align-center justify-content-center ml-20 ">
                            </div>

                        </>
                    )}
                </Formik>

            </CardBody>
        </Card>
    );
}


export default injectIntl(GiftDetail)