import {GET_GIFT_ANONYMOUS_ERROR,GET_GIFT__ANONYMOUS_SUCCESS} from "../actiontype"

const giftA = {
  giftsAnonymous: [],
  clientMessage:""

};


export const giftAnonymousReducer= (state = giftA, action) => {
    switch (action.type) {
      case GET_GIFT__ANONYMOUS_SUCCESS:
        return { ...state, giftsAnonymous: action.payload }
      case GET_GIFT_ANONYMOUS_ERROR:
        return { ...state, clientMessage: action.payload }

      default:
        return state
    }
  }
