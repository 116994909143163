
import * as gift from "../actiontype"

const initialGiftState = {
    scrapingGift: {},
    clientMessage: ""
};

//root reducera ekle

export default (state = initialGiftState, action) => {
    switch (action.type) {
        case gift.GET_GIFT_WEB_SCRAPING_SUCCESS:
            return { ...state, scrapingGift: action.payload }
        case gift.GET_GIFT_WEB_SCRAPING_ERROR:
            return { ...state, clientMessage: action.payload }
        default:
            return state
    }
}