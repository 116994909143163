import React from "react";
import { Passers } from "prop-passer";
import { FaShare } from 'react-icons/fa'
import Modal1 from './Modal'
//import { useUID, useUIDSeed } from 'react-uid';
import { useIntl, FormattedMessage } from "react-intl";

import { useDispatch } from "react-redux";
import {mainUrl} from '../../../../../Redux/BaseURL'

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TelegramIcon,

  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,


} from "react-share";

import SubShareCSS from "./css";
//const TEXT="Omer%20Faruk%27s%20Birthday";
//const URL="https%3A%2F%2Fyepp.app%2Fevent%2Feventdetailanonymous?id=0d7542a0-f2bd-4d43-ad35-89077d8e7b53"



export default function SubShare (props) {
 
  
  const dispatch = useDispatch();
  //const uid_facebook = useUID(); 
  //const uid_watsapp = useUID(); 
  //const uid_twitter = useUID(); 
  //const uid_telegram = useUID(); 
  //const uid_mail = useUID(); 

  
   const shareInvitation=(id,name,track_number)=>{
     //dispatch(postInvitationShare(id,name,track_number))


  }
  
  

//const newadres="https://yepp.app/event/eventdetailanonymous/"+props.eventid
const newadres=mainUrl+"event/eventdetailanonymous/"+props.eventid




    //var watsapp = "https://api.whatsapp.com/send/?phone&text=" + adres + "&app_absent=0"
    //var twitter = "https://twitter.com/intent/tweet?url=" + adres + "&text=" +props.baslik
    //var telegram = "https://t.me/share/url?url=" + adres + "&text=" + props.baslik
    //var email = adres + "?subject=" +props.baslik + "&body=" + adres
    //var facebook = "https://www.facebook.com/dialog/share?app_id=87741124305&href=" + adres + "%26feature%3Dshare&display=popup"


    const ShareList = Passers({

      className: "network__share-button float-left ml-8  mt-5",
    })({
      className: "network cursor-pointer hover transition--default float-left  ",

    })("li");

    return (
      <div>
        <p style={{ fontSize: 15 }}><FormattedMessage id="share" /></p>


        <SubShareCSS className="mr-20">
          <ShareList>
 
            <FacebookShareButton  
            
               
             
               name="Facebook"
              url={newadres}

            >
              <FacebookIcon  
                size={props.size}
                className="rounded-circle"
              />
              <p>Facebook</p>
              
            </FacebookShareButton>

            <EmailShareButton url={newadres}  subject={props.baslik} 
          > 

              <EmailIcon size={props.size} className="rounded-circle"    />
              <p>Email</p>
            </EmailShareButton>

            <TelegramShareButton 
            
              url={newadres}
              title={props.baslik}

              className="rounded-circle"
            >
              <TelegramIcon  
                size={props.size}
                className="rounded-circle"
              />
              <p>Telegram</p>
            </TelegramShareButton>


            <TwitterShareButton 
            
              url={newadres}
              title={props.baslik}
            >
              <TwitterIcon 
                size={props.size}
                className="rounded-circle"
              />
              <p>Twitter</p>
            </TwitterShareButton>

            <WhatsappShareButton    
            
              url={newadres}



              
            >
              <WhatsappIcon 
               
             className="rounded-circle" size={props.size} />
              <p>Whatsapp</p>
            </WhatsappShareButton>

           









          </ShareList>
         





        </SubShareCSS>

      </div>
    );
  }


