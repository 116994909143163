
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
export function DropdownMenu1() {
    const categories = useSelector(state => state.categories)


    useEffect(() => {
    }, [])

    return (<>
        {/*begin::Navigation*/}

        <ul className="navi navi-hover">
            <li className="navi-separator mb-3 opacity-70"></li>

            {
                categories.map(category =>
                    <li key={category.id} className="navi-item">
                        <a href="/event/addevent"  className="navi-link">
                            <span className="navi-text">
                                <span className="label label-xl label-inline label-light-success"><FormattedMessage id={category.id} /></span>
                            </span>
                        </a>
                    </li>
                )
            }
        </ul>

        {/*end::Navigation*/}
    </>);
}


