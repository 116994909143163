/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef,useState,useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { DropdownMenu1 } from "../../../../_partials/dropdowns";
import { toAbsoluteUrl } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";



const QuickActionsDropdownToggle = forwardRef((props, ref) => {

  const [durum,setdurum]=useState(false)
  

  useEffect(() => {
   
    if( window.location.pathname == "/dashboard" || window.location.pathname=="/user-profile/profile-overview"){
      setdurum(false)
      
    }
    else{
      setdurum(true)
    }
  });
  return (
    <a hidden={durum}
      ref={ref}
      href="/event/addevent"
      id="kt_subheader_quick_actions"
      className="btn btn-sm btn-clean btn-icon"
    >
      {" "}
     
       <div  className="d-flex align-items-center">
      
       <a   href="/event/addevent" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
         data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
         <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date"><FormattedMessage id="actions_btn" /></span>
       </a> 
     </div>
     
      
    </a>
  );
});

export function QuickActions() {
  return (
    <>
      <Dropdown className="dropdown-inline" drop="down" alignRight>
        <Dropdown.Toggle
          as={QuickActionsDropdownToggle}
          id="dropdown-toggle-quick-actions-subheader"
        />
        {/* <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
          <DropdownMenu1 />
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
}
