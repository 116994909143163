import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { injectIntl } from "react-intl";


function PopupModal(props) {
    const { intl } = props;
    //butontext
    //type
    //text
    //title
    //color
    //bgcolor

    return (
        <div>
            <Modal

                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="alert-class"
                centered
            >
                <div style={{ backgroundColor: "#fff", color: "#000", borderRadius: "5px" }}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className="text-center">

                            <div style={{ color: props.color }}>{props.title}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.text}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !props?.yesNo
                                ? <button className="modal-button-style" onClick={props.toPushPage ? () => props.toPushPage() : props.onHide}>{intl.formatMessage({ id: "alert_ok" })}</button>
                                : <div>
                                    <button className="modal-button-style" style={{ marginRight: "10px" }} onClick={() => {
                                        props.handleDelete()
                                        props.onHide()
                                    }} >{intl.formatMessage({ id: "alert_yes" })}</button>
                                    <button className="modal-button-style" onClick={props.onHide}>{intl.formatMessage({ id: "alert_no" })}</button>
                                </div>


                        }

                    </Modal.Footer>
                </div>
            </Modal>

        </div>
    );
}

export default injectIntl(PopupModal)