import {GET_PRIORITY_SUCCESS} from "../actiontype"

const initialAuthState = {
  priority: []
};

export const priorityReducer = (state = initialAuthState.priority, action) => {
  switch (action.type) {
    case GET_PRIORITY_SUCCESS: 
      return action.payload
    
    default:
      return state;
  }
}
