
import React, { useEffect, useState } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "./custom.css"
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment"
import tr from "date-fns/locale/tr"
import en from "date-fns/locale/en-US"
registerLocale('tr', tr)
function Saat(props) {
    const [startDate, setStartDate] = useState();
    const [maxDate, setmaxDate] = useState(moment(`${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear() + 1}`, "DD-MM-YYYY").toDate())

    useEffect(() => {
        if (props.defaultDate) {
            setStartDate(new Date(props.defaultDate))
        }
    }, [props.defaultDate])

    const { intl } = props;
    return (
        <div>
            <div className="date-picker-container">
                <DatePicker {...props.fielder} locale="tr" minDate={moment().toDate()} maxDate={maxDate} value={startDate} name={props.inputName} id="datepicker" placeholderText={intl.formatMessage({ id: "date", })} selected={startDate} onChange={(date) => {

                    props.former.getFieldHelpers("endDate").setValue(date)
                    setStartDate(date)
                    props.handleDateChange(date)
                }} />
                <label htmlFor="datepicker">
                    <span class="input-group-text">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </span>
                </label>
            </div>
        </div>
    )
}
export default injectIntl((Saat));

