import React, { Component } from 'react';
import "./payment.css";
class Information extends Component {

	constructor(props) {
		super(props);
		this.formref = React.createRef();
	}

	componentDidMount() {
		setTimeout(() => {
			this.formref.current.classList.add('active');
		}, 100);
	}

	render() {
		return (
			<div className="formcard" ref={this.formref}>
				<div className="form">
					<div className="card space icon-relative formarea">
						<input type="text" className="input" placeholder="Email" />
					</div>
					<div className="card space icon-relative formarea">
						<input type="text" className="input" placeholder="Name" />
					</div>
					<div className="card space icon-relative formarea">
						<input type="text" className="input" placeholder="Surname" />
					</div>
					<div className="card-grp space">
						<div className="card-item icon-relative formarea">
							<input type="text" name="expiry-data" className="input" placeholder="Phone Number" />
							<input type="text" className="input" placeholder="Email" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Information;