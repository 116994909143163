

export const imageSizeFonk = (width, height) => {
    if (width < height) {
        if (width + 1000 < height) {
            return { width: 45, height: 100 }
        }
        else if (width + 700 < height) {
            return { width: 55, height: 100 }
        }
        else if (width + 400 < height) {
            return { width: 65, height: 100 }
        }
        else {
            return { width: 75, height: 100 }
        }
    }
    else if (width > height) {
        if (width > height + 1000) {
            return { width: 100, height: 45 }
        }
        else if (width > height + 700) {
            return { width: 100, height: 55 }
        }
        else if (width > height + 400) {
            return { width: 100, height: 65 }
        }
        else {
            return { width: 100, height: 75 }
        }
    }
    else {
        return { width: 90, height: 100 }
    }
}