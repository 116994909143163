/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import ProductEditForm from "./ProductEditForm";
import { Specifications } from "../product-specifications/Specifications";
import { SpecificationsUIProvider } from "../product-specifications/SpecificationsUIContext";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { RemarksUIProvider } from "../product-remarks/RemarksUIContext";
import { Remarks } from "../product-remarks/Remarks";
import PopupModal from "../../../../UserProfile/PopupModal";
import { getStatusAction } from "../../../../Redux/actions/statusActions";
import { useIntl, FormattedMessage } from "react-intl";


const initEvent = {
  id: undefined,
  image: "",
  title: "",
  description: "",
  categoryId: "",
  endDate: "",
  statusId: ""
};

export function ProductEdit({

  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();
  const intl = useIntl();
  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [alertField, setAlertField] = useState({
    title: "",
    text: "",
    show: false,
    color: "#23ba47"
  })
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProduct(id));
  }, [id, dispatch]);




  useEffect(() => {
    let _title = id ? "" : <FormattedMessage id="new_product" />;
    if (productForEdit && id) {
      _title = `Edit product '${productForEdit.manufacture} ${productForEdit.model} - ${productForEdit.modelYear}'`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit, id]);

  const saveProduct = (values, resetAction) => {
    dispatch(actions.createProduct(values, setAlertField, intl, resetAction))//alertler

  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    history.push(`/event/eventlist`);
  };

  return (
    <Card>
      <PopupModal
        {...alertField}
        show={alertField.show}
        toPushPage={() => backToProductsList()}
        onHide={() => setAlertField(value => ({ ...value, show: false }))}
      />
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            style={{ color: "#fff", backgroundColor: "#b22f91" }}
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            <FormattedMessage id="back" />
          </button>

          {`  `}
          <button
            style={{ color: "#fff", backgroundColor: "#b22f91" }}
            type="submit"
            className="btn ml-3"
            onClick={saveProductClick}
          >
            <i class="fas fa-check"></i>
            <FormattedMessage id="save" />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
            </a>
          </li>
          {id && (
            <>
              {" "}


            </>
          )}
        </ul>
        <div className="mt-10">
          {tab === "basic" && (
            <ProductEditForm
              actionsLoading={actionsLoading}
              product={productForEdit || initEvent}
              btnRef={btnRef}
              saveProduct={saveProduct}
            />
          )}
          {tab === "remarks" && id && (
            <RemarksUIProvider currentProductId={id}>
              <Remarks />
            </RemarksUIProvider>
          )}
          {tab === "specs" && id && (
            <SpecificationsUIProvider currentProductId={id}>
              <Specifications />
            </SpecificationsUIProvider>
          )}
        </div>
      </CardBody>
    </Card>
  );
}