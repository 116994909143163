import React, { Component } from 'react';
import Form from './Form';
import Information from './Information';
import "./payment.css";
import { Button } from 'react-bootstrap-buttons';
import axios from 'axios';
class Container extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cartInfo: {
				billingEmail: "",
				nameLastname: "",
				number: "",
				monthYear: "",
				cvv: ""
			},
			errorMessage: "",
			imageClass: ""
		};
		this.handleTextChange = this.handleTextChange.bind(this)
	}

	handleTextChange(e) {
		const { name, value } = e.target;
		if (name === "nameLastname") {
			var nameSurnameRegex = /[A-ZĞÜÇİŞÖöüşğç]|\s/
			var nameSurnameSpaceRegex = /[A-ZĞÜÇİŞÖöüşğç]/
			if (value.length > 0) {
				var deger = value[value.length - 1].toUpperCase()
				if (value[0] === " ") {
					this.setState({ cartInfo: { ...this.state.cartInfo, [name]: value.slice(0, -1) } })
					return
				}
				if (deger === " " && value.length > 1) {
					if (!nameSurnameSpaceRegex.test(value[value.length - 2].toUpperCase())) {
						this.setState({ cartInfo: { ...this.state.cartInfo, [name]: value.slice(0, -1) } })
						return
					}
				}
				if (!nameSurnameRegex.test(deger)) {
					this.setState({ cartInfo: { ...this.state.cartInfo, [name]: value.slice(0, -1) } })
					return
				}
			}
			this.setState({
				cartInfo: {
					...this.state.cartInfo,
					[name]: value
				}
			})
		}
		else {
			this.setState({ cartInfo: { ...this.state.cartInfo, [name]: value } }, () => {
			})
		}
	}

	cartRegexControl() {
		const cartRegex = {
			email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
			nameLastname: /[a-zA-ZĞÜÇİŞÖışçöüğ]|\s/g,
			number: /[0-9]{16}/g,
			monthYear: /[0-9]{6}/g,
			cvv: /[0-9]{3}/g
		}
		let control = false
		if (!cartRegex.email.test(this.state.cartInfo.billingEmail)) {
			// console.log("email hatalı")
			control = true
		}
		if (!cartRegex.nameLastname.test(this.state.cartInfo.nameLastname)) {
			// console.log("nameLastname hatalı")
			control = true
		}
		if (!cartRegex.number.test(this.state.cartInfo.number.replace(/-/g, ""))) {
			// console.log("number hatalı")
			control = true
		}
		if (!cartRegex.monthYear.test(this.state.cartInfo.monthYear.replace(/\s|\//g, ""))) {
			// console.log("monthYear hatalı")
			control = true
		}
		if (!cartRegex.cvv.test(this.state.cartInfo.cvv)) {
			// console.log("cvv hatalı")
			control = true
		}

		if (control === true) {
			return true
		}
		else {
			return false
		}
	}

	handlePay = (e) => {
		/*
			axios.post("http://yepp-api.azurewebsites.net/api/Paymes/Pay", {
			"number": "5218071709033821",
			"expiryMonth": "092023",
			"expiryYear": "23",
			"cvv": "295",
			"billingFirstname": "Çetin KOSİFOĞLU",
			"billingLastname": "KOSİFOĞLU",
			"billingEmail": "cetin@texinsight.com",
			"clientIp": "188.57.112.97",
			"productName": "yepp.app test",
			"EventId": "1cc7bf0c-9c7f-4e32-a7a3-b8bd00f84849"
		})
			.then(result => {
				window.open(result.data.paymentResult.url, '_blank');

			})
		*/
		if (this.cartRegexControl()) {
			this.setState({ errorMessage: "Kart bilgileri hatalı" })
			return
		}
		this.setState({ errorMessage: "" })
		let nameLastname = ""
		let lastName = ""
		let name = ""
		let monthYear = ""
		let month = ""
		let year = ""
		let number = ""
		try {
			nameLastname = this.state.cartInfo.nameLastname.split(" ")
			lastName = nameLastname[nameLastname.length - 1]
			name = nameLastname.slice(0, nameLastname.length - 1).join(" ")
			monthYear = this.state.cartInfo.monthYear.split("/")
			month = monthYear[0].replace(" ", "")
			year = monthYear[1].replace(" ", "").slice(monthYear[1].length - 3, monthYear[1].length - 1)
			number = this.state.cartInfo.number.replace(/-/g, "")
		} catch (error) {
			this.setState({ errorMessage: "Beklenmedik hata ile karşılaşıldı tekrar deneyiniz!" })
			return
		}
		this.setState({ imageClass: "loading-image-class" })

		axios.post("http://yepp-api.azurewebsites.net/api/Paymes/Pay", {
			"number": number,
			"expiryMonth": month,
			"expiryYear": year,
			"cvv": this.state.cartInfo.cvv,
			"billingFirstname": name,
			"billingLastname": lastName,
			"billingEmail": this.state.cartInfo.billingEmail,
			"clientIp": this.props.ipAddress,
			"productName": this.props.title,
			"EventId": this.props.eventId
		})
			.then(result => {
				this.setState({ imageClass: "" })
				window.open(result.data.paymentResult.url, '_self');
			})

	}

	render() {
		return (
			<div>
				<section className="right">
					<section className="texthead"> Kart Bilgileri: </section>
					<div style={{ height: "20px", color: "red" }}> {this.state.errorMessage} </div>
					<Form handleTextChange={this.handleTextChange} state={this.state.cartInfo} />
				</section>
				<div style={{ position: "relative" }}>
					<Button className="button1 pay-button" onClick={(e) => this.handlePay(e)} variant="dark">Katkı Sağla</Button>
					<img className={"default-image-class " + this.state.imageClass} src={require("../../../../../../yepp_media/loading/loading2.gif")} width="200" style={{ position: "absolute", zIndex: "0", transform: "translate(-25%,-25%)" }} alt="" />
				</div>
			</div>
		);
	}
}
export default Container;