
import {GET_CATEGORIES_SUCCESS,GET_STATUS_SUCCESS} from "../actiontype"


const initialAuthState = {
  categories: [],
   status: []
};

export const categoryReducer = (state = initialAuthState.categories, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS: 
      return action.payload
    
    default:
      return state;
  }
}

