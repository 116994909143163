
import {GET_STATUS_SUCCESS} from "../actiontype"

const initialAuthState = {
  status: []
};

export const statusReducer = (state = initialAuthState.status, action) => {
  switch (action.type) {
    case GET_STATUS_SUCCESS: 
      return action.payload
    
    default:
      return state;
  }
}