import * as requestFromServer from "./productsCrud";
import { productsSlice, callTypes } from "./productsSlice";

const { actions } = productsSlice;

export const fetchProducts = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProducts(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.productsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProduct = id => dispatch => {
  if (!id) {
    return dispatch(actions.productFetched({ productForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(id)
    .then(response => {
      const product = response.data;
      dispatch(actions.productFetched({ productForEdit: product }));
    })
    .catch(error => {
      error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProduct = eventid => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProduct(eventid)
    .then(response => {
      dispatch(actions.productDeleted({ eventid }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};




export const createProduct = (event, setAlertField, intl, resetAction) => async dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProduct(event)
    .then(result => {
      event = { ...event, id: result.data.id }
      dispatch(actions.productCreated({ event }));

      if (event.image !== "") {
        requestFromServer
          .createEventImage({ image: event.image, eventid: result.data.id })
          .then(result2 => {
            event = { ...event, image: result2.data.imgPath, id: result.data.id }
            dispatch(actions.productUpdated({ event }));

            requestFromServer
              .updateProduct(event)
              .then(() => {
                resetAction.setstatePicture("")
              })
              .catch(err => {

              })
            setAlertField({
              title: intl?.formatMessage({ id: "pass_successfull", }),
              text: intl?.formatMessage({ id: "event_true", }),
              show: true,
              color: "#23ba47"
            })
          })
          .catch(err => {
            setAlertField({
              title: intl?.formatMessage({ id: "pass_fail", }),
              text: intl?.formatMessage({ id: "image_false", }),
              show: true,
              color: "red"
            })
          })
      }
      else {
        setAlertField({
          title: intl?.formatMessage({ id: "pass_successfull", }),
          text: intl?.formatMessage({ id: "event_true", }),
          show: true,
          color: "#23ba47"

        })
      }
      resetAction.resetForm({})
      resetAction.setSelectedOrganization(resetAction.defaultOrganization)
      resetAction.setSelectedStatus(resetAction.defaultStatus)
      //resetAction.setSelectedDate(new Date())

    })
    .catch(error => {
      error.clientMessage = "Can't create product";
      if (error.response.status === 404) {
        setAlertField({
          title: intl?.formatMessage({ id: "pass_fail", }),
          text: intl?.formatMessage({ id: "max_event", }),
          show: true,
          color: "red"
        })
      }
      else {
        setAlertField({
          title: intl?.formatMessage({ id: "pass_fail", }),
          text: intl?.formatMessage({ id: "max_event", }),
          show: true,
          color: "red"

        })
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProduct = (event, setAlertField, intl) => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProduct(event)
    .then(async (result) => {

      var image = ""

      if (image.search("data:image") !== -1) {
        image = event.image
        event = { ...event, image: "", id: result.data.id }
      }


      dispatch(actions.productUpdated({ event }));

      if (image.search("data:image") !== -1) {
        requestFromServer
          .createEventImage({ image: image, eventid: result.data.id })
          .then(result2 => {
            event = { ...event, image: result2.data.img, id: result.data.id }
            dispatch(actions.productUpdated({ event }));
            setAlertField({
              title: intl?.formatMessage({ id: "pass_successfull", }),
              text: intl?.formatMessage({ id: "product_detail", }),
              show: true,
              color: "#23ba47"
            })
          })
          .catch(err => {
            setAlertField({
              title: intl?.formatMessage({ id: "pass_fail", }),
              text: intl?.formatMessage({ id: "product_datail_image_fail", }),
              show: true,
              color: "#23ba47"
            })
          })
      }
      else {
        setAlertField({
          title: intl?.formatMessage({ id: "pass_successfull", }),
          text: intl?.formatMessage({ id: "product_detail", }),
          show: true,
          color: "#23ba47"
        })
      }
      //--------------
    })
    .catch(error => {
      error.clientMessage = "Can't update event";
      setAlertField({
        title: intl?.formatMessage({ id: "pass_fail", }),
        text: intl?.formatMessage({ id: "product_detail_fail", }),
        show: true,
        color: "#23ba47"
      })
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProductsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForProducts(ids, status)
    .then(() => {
      dispatch(actions.productsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update products status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProducts = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProducts(ids)
    .then(() => {
      dispatch(actions.productsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//---------------------------------
export const getProductByIdAction = (userid) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProductById(userid)
    .then((result) => {
      dispatch(actions.productsFetched({ entities: result.data }));
    })
    .catch(error => {
      error.clientMessage = "eventler getirilemedi";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const getEventAnonyByIdAction = (eventanonid) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAnonyEventDetail(eventanonid)
    .then((result) => {
      dispatch(actions.productsFetched({ entities: result.data }));
    })
    .catch(error => {
      error.clientMessage = "eventanonlar getirilemedi";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

