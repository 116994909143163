/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import {DropdownCustomToggler, DropdownMenu4} from "../../dropdowns";

export function ListsWidget1({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Size Gelen Davetiyeler
          </h3>
          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu4 />
              </Dropdown.Menu>
            </Dropdown>            
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-primary mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Okan Dunay'dan Happ Hour Daveti
              </a>
              <span className="text-muted">04.24.2012 -  15:00 - 18:00 arası</span>
            </div>
          </div>

          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-warning mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-warning">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>
            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark-75 text-hover-primary mb-1 font-size-lg"
              >
                Ömer Faruk'dan İçelim Coşalım daveti
              </a>
              <span className="text-muted">04.25.2021 - 20:00  - 03:00 arası</span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-success">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group-chat.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Çetin' den Düğünümüz var daveti
              </a>
              <span className="text-muted">07.07.2021 19:00 - 22:00</span>
            </div>
          </div>

          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-danger mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-danger">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Attachment2.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Hakan'dan ne verirseniz kabulüm daveti.
              </a>
              <span className="text-muted">Zaman sınırlaması yok :)</span>
            </div>
          </div>

          <div className="d-flex align-items-center mb-2">
            <div className="symbol symbol-40 symbol-light-info mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg  svg-icon-info">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Shield-user.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                Ece' den Sosyal sorumluluk için kitap biriktirelim daveti.
              </a>
              <span className="text-muted">05.05.2021 - 07.05.2021 arası</span>
            </div>            
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="symbol symbol-40 symbol-light-info mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg  svg-icon-info">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Shield-user.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                İbrahim'den bebeğimin doğum günü daveti
              </a>
              <span className="text-muted">05.05.2021 19:00 - 21:00</span>
            </div>
            
          </div>

        </div>
      </div>
    </>
  );
}
