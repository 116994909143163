
import * as gift from "../actiontype"

const initialGiftState = {
  gifts: [],
  clientMessage: "",
  selectGift: {},
  marketList: []
};



export default (state = initialGiftState, action) => {
  switch (action.type) {
    case gift.GET_GIFT_SUCCESS:
      return { ...state, gifts: action.payload }
    case gift.GET_GIFT_ERROR:
      return { ...state, clientMessage: action.payload }

    //-----
    case gift.CREATE_GIFT_SUCCESS:
      return { ...state, gifts: [...state.gifts, action.payload] }
    case gift.CREATE_GIFT_ERROR:
      return { ...state, clientMessage: action.payload }


    case gift.GET_GIFT_DETAIL_BY_ID_SUCCESS:
      return { ...state, selectGift: action.payload }
    case gift.GET_GIFT_DETAIL_BY_ID_ERROR:
      return { ...state, clientMessage: action.payload }
    //-----




    //---------- gift market list ---------------

    case gift.GET_GIFT_MARKET_LIST_SUCCESS:
      return { ...state, marketList: action.payload }
    case gift.GET_GIFT_MARKET_LIST_ERROR:
      return { ...state, clientMessage: action.payload }

    //---------- gift market list ---------------

    case gift.DELETE_GIFT_BY_ID_SUCCESS:
      return { ...state, gifts: state.gifts.filter(gift => gift.id !== action.payload) }
    case gift.DELETE_GIFT_BY_ID_ERROR:
      return { ...state, clientMessage: action.payload }

    case gift.UPDATE_GIFT_QUANTITY_DEC_SUCCESS:
      return { ...state, gifts: state.gifts.map(gift => gift.id === action.payload ? { ...gift, quantity: gift.quantity - 1 } : gift) }
    case gift.UPDATE_GIFT_QUANTITY_DEC_ERROR:
      return { ...state, clientMessage: action.payload }

      case gift.UPDATE_GIFT_QUANTITY_INC_SUCCESS:
      return { ...state, gifts: state.gifts.map(gift => gift.id === action.payload ? { ...gift, quantity: gift.quantity + 1 } : gift) }
    case gift.UPDATE_GIFT_QUANTITY_INC_ERROR:
      return { ...state, clientMessage: action.payload }

    default:
      return state
  }
}