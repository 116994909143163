import { POST_EMAIL_SUCCESS, POST_EMAIL_ERROR } from "./actiontype"
import axios from "axios"


const EMAIL_ADD = "http://yepp-api.azurewebsites.net/api/EmailList/AddEmailList"


export function requestEmail(email, setAlertField) {
  return axios.post(EMAIL_ADD, {
    email: email
  }).then((response) => {
    setAlertField({
      title: "İşlem Başarılı",
      text: "Email adresiniz başarılı bir şekilde tarafımıza ulaşmıştır",
      show: true,
      color: "#b22f91"
    })
  })
    .catch((err) => {
    }
    )
}

