
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import PopupModal from "../../UserProfile/PopupModal";

const initialValues = {
  name: "",
  surname: "",
  email: "",
  telephone: null,
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, intl.formatMessage({
        id: "alert_length_nameMin",
      }))
      .max(25, intl.formatMessage({
        id: "alert_length_nameMax",
      }))
      .required(intl.formatMessage({
        id: "alert_empty",
      }))
      .matches(/^[aA-zZğüşıöçĞÜŞİÖÇ\s]+$/, intl.formatMessage({
        id: "alert_alpha",
      })),
    surname: Yup.string()
      .min(2, intl.formatMessage({
        id: "alert_length_nameMin",
      }))
      .max(25, intl.formatMessage({
        id: "alert_length_nameMax",
      }))
      .required(intl.formatMessage({
        id: "alert_empty",
      }))
      .matches(/^[aA-zZğüşıöçĞÜŞİÖÇ\s]+$/, intl.formatMessage({
        id: "alert_alpha",
      })),
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.VALIDATION.WRONG.EMAIL.FORMAT",
        }))
      .min(3,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
        }))
      .max(50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
        }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    telephone: Yup.string()

      .min(6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
        }))
      .max(25,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
        }))
      .required(intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }))
      .nullable(),
    password: Yup.string()
      .min(6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
        }))
      .max(25,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
        }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "AUTH.VALIDATION.PASSWORD.DIDNT.MATCH",
          })
        ),
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.ACCEPT.TERMS",
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const [alertField, setAlertField] = useState({
    title: intl.formatMessage({id:"pass_successfull"}),
    text: intl.formatMessage({id:"register_alert_true"}),
    show: false,
    color: "#23ba47"
  })


  const [validAlert, setvalidAlert] = useState(["", false])
  const [deneme, setdeneme] = useState("")

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.name, values.surname, values.password, values.telephone)
        .then(result => {
          if (result.status === 200) {
            setAlertField(value => ({ ...value, show: true }))
          }
          disableLoading();
          setSubmitting(false);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setvalidAlert([intl.formatMessage({ id: "alert_signup_400", }), false])
          }
          else if (error.response.status === 403) {
            setvalidAlert([intl.formatMessage({ id: "false_mail" }), false])
          }
          else {
            setvalidAlert([intl.formatMessage({ id: "activation_false_sent" }), false])
          }
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });


  const handleyaz = (e) => {
    setdeneme(e)
  }

  useEffect(() => {
  }, [deneme])

  useEffect(() => {
    ref.current.querySelector(".phone-number-text").setAttribute("name", "telephone");
  }, [])

  const history = useHistory()

  const ref = useRef()

  const toLoginPage = () => {
    history.push("/auth/login")
  }

  return (

    <div className="login-form login-signin mt-30" style={{ display: "block" }}>
      <PopupModal
        {...alertField}
        show={alertField.show}
        toPushPage={() => toLoginPage()}
        onHide={() => setAlertField(value => ({ ...value, show: false }))}
      />
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESC" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        //ref={ref}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {/* {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )} */}

        {
          validAlert[0] !== "" && (
            validAlert[1]
              ? <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold"> {validAlert[0]} </div>
              </div>
              : <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold"> {validAlert[0]} </div>
              </div>
          )
        }


        {/* end: Alert */}

        {/* begin: name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.NAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: name */}

        {/* begin: surname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.SURNAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "surname"
            )}`}
            name="surname"
            {...formik.getFieldProps("surname")}
          />
          {formik.touched.surname && formik.errors.surname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.surname}</div>
            </div>
          ) : null}
        </div>
        {/* end: surname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input

            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.EMAIL",
            })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: telephone */}
        <div ref={ref} className="form-group fv-plugins-icon-container">


          <PhoneInput
            inputStyle={{ width: "100%", backgroundColor: "#F3F6F9", borderColor: "#F3F6F9", color: "#3F4254", height: "auto !important", paddingTop: "1.2rem", paddingBottom: "1.2rem", fontSize: "1rem", lineHeight: "1.5", transition: "300ms all" }}
            country={'tr'}
            inputClass="phone-number-text"
            placeholder="Telephone"

            inputClass={`phone-number-text form-control form-control-solid h-auto ${getInputClasses(
              "telephone"
            )}`}
            {...formik.getFieldProps("telephone")}
            onChange={phone => {
              formik.getFieldHelpers("telephone").setValue(phone)
              handleyaz(phone)
            }}
          />

          {/* <MuiPhoneNumber
            inputClass="phone-number-text-input"
            id="phone"
            name="telephone"
            data-cy="user-phone"
            defaultCountry="tr"
            {...formik.getFieldProps("telephone")}
          /> */}
          {/* <MaskedInput
            mask={phoneNumberMask}
            type="text"
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.TELEPHONE",
            })}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "telephone"
            )}`}
            name="telephone"

            {...formik.getFieldProps("telephone")}
          /> 
           <input*/}
          {/* <input
            type="text"
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.TELEPHONE",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "telephone"
            )}`}
            name="telephone"
            {...formik.getFieldProps("telephone")}
          /> */}
          {formik.touched.telephone && formik.errors.telephone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.telephone}</div>
            </div>
          ) : null}
        </div>
        {/* end: telephone */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.PASSWORD",
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder=
            {intl.formatMessage({
              id: "AUTH.INPUT.CONFIRM_PASSWORD",
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({
                id: "AUTH.INPUT.AGREEMENT",
              })}
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              {intl.formatMessage({
                id: "AUTH.GENERAL.SIGNUP_BUTTON",
              })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              {intl.formatMessage({
                id: "AUTH.GENERAL.BACK_BUTTON",
              })}
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
