// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { FormattedMessage, injectIntl } from "react-intl";
import { AdvanceTablesWidget4 } from '../../../../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget4'
import { MixedWidget14 } from '../../../../../../_metronic/_partials/widgets/mixed/MixedWidget14'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useProductsUIContext } from "../ProductsUIContext";
import { useIntl } from "react-intl";
import { getAllProducts } from "../../../_redux/products/productsCrud";
import { useHistory } from "react-router";
import PopupModal from "../../../../UserProfile/PopupModal";

export function ProductsTable() {
  const intl = useIntl();
  // Products UI Context
  const history = useHistory()
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    };
  }, [productsUIContext]);
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => ({ userInfo: state.auth.user }))
  const status = useSelector(state => state.status)
  useEffect(() => {

    // // clear selections list
    productsUIProps.setIds([]);
    // // server call by queryParams
    dispatch(actions.getProductByIdAction(userInfo.id));
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);

  useEffect(() => {
  }, [status])

  // Getting curret state of products list from store (Redux)
  const { events } = useSelector(
    (state) => ({ events: state.products }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = events;

  useEffect(() => {
  }, [events])

  const [alertField, setAlertField] = useState({
    title: "",
    text: intl.formatMessage({ id: "delete_alert", }),
    yesNo: true
  })
  const [modalShow, setModalShow] = useState({
    show: false,
    eventid: null
  })

  // Products Redux state

  const handleDelete = (eventid) => {
    dispatch(actions.deleteProduct(eventid))
  }

  const update = (rowId) => {


    history.push("/event/eventdetail/" + rowId)


  }

  // Table columns


  const columns = [
    {
      dataField: "title",
      text: intl.formatMessage({ id: "event_list_title", }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "categoryId",
      text: intl.formatMessage({ id: "new_product_org", }),
      sort: true,
      sortCaret: sortCaret,
    },


    {
      dataField: "statusId",
      text: intl.formatMessage({ id: "event_list_status", }),
      sort: true,
      sortCaret: sortCaret,

    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "event_list_endDate", }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'df2',
      isDummyField: true,
      text: "",
      formatter: (cellContent, row) => {
        return (
          <h5>
            <button style={{ color: "#fff", backgroundColor: "#b22f91" }} onClick={() => update(row.id)} className="modal-button-style"><FormattedMessage id="edit" /></button>
          </h5>
        );
      }
    },

    {
      dataField: 'df1',
      isDummyField: true,
      text: "",
      formatter: (cellContent, row) => {
        return (
          <h5>
            <button type="button"
              className="btn" style={{ color: "#fff", backgroundColor: "#000" }} onClick={() => setModalShow({ show: true, eventid: row.id })} className="modal-button-style"><FormattedMessage id="delete" /></button>
          </h5>
        );
      }
    },

  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber,
  };

  /*
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (e.target.tagName !== "BUTTON") {
        history.push("/event/eventdetail/" + row.id)
      }
    }
  };
  */

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>

        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <PopupModal
                {...alertField}
                setAlertField={setAlertField}

                show={modalShow.show}
                handleDelete={() => handleDelete(modalShow.eventid)}
                onHide={() => setModalShow(false)}
              />
              <BootstrapTable
                wrapperClasses="table-responsive mt-10 ml-10"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                rowClasses="custom-row-class"
                data={entities ? entities.map(replac => {
                  return { ...replac, categoryId: intl.formatMessage({ id: replac.categoryId, }), statusId: intl.formatMessage({ id: replac.statusId, }), endDate: replac?.endDate.slice(0, -9) }
                }) : []}
                columns={columns}

                defaultSorted={uiHelpers.defaultSorted}
                //rowEvents={rowEvents}
                onTableChange={getHandlerTableChange(
                  productsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: productsUIProps.ids,
                  setIds: productsUIProps.setIds,
                })}


                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>

            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}