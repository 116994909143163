import React, { Suspense, lazy, useLayoutEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAction } from "./modules/Redux/actions/categoryActions"
import { getPriorityAction } from "./modules/Redux/actions/priorityActions"
import { ProductEdit } from './modules/ECommerce/pages/products/product-edit/ProductEdit'
import AddGift from './modules/ECommerce/pages/products/product-edit/AddGift'
import UpdateGift from './modules/ECommerce/pages/products/product-edit/UpdateGift'
import { getStatusAction } from "./modules/Redux/actions/statusActions"
import ProductDetail from './modules/ECommerce/pages/products/product-edit/ProductDetail'
import { getProductByIdAction } from "./modules/ECommerce/_redux/products/productsActions";
import { ProductsPage } from "./modules/ECommerce/pages/products/ProductsPage";
import GiftDetail from "./modules/ECommerce/pages/products/product-edit/GiftDetail";
import { actions } from "./modules/Auth";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);


export default function BasePage() {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(state => ({ userInfo: state.auth.user }))


  useLayoutEffect(() => {
    dispatch(getCategoriesAction(actions,dispatch))
    dispatch(getStatusAction())
    dispatch(getPriorityAction())
    dispatch(getProductByIdAction(userInfo.id));
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route exact path="/" component={DashboardPage} />
        <Route exact path="/event/addevent" component={ProductEdit} />
        <Route exact path="/event/eventlist" component={ProductsPage} />
        <Route exact path="/event/eventdetail/:eventid" component={ProductDetail} />
        <Route exact path="/gift/giftdetail/:giftid" component={GiftDetail} />
        <Route exact path="/gift/addgift/:eventid" component={AddGift} />
        <Route exact path="/user-profile" component={UserProfilepage} />
        <Route exact path="/gift/updategift" component={UpdateGift} />
        <Redirect from="/*" to="/" />
      </Switch>
    </Suspense>
  );
}