import { GET_PRIORITY_SUCCESS,GET_PRIORITY_ERROR} from "../actiontype"
import axios from "axios"

const GET_PRIORITY = "http://yepp-api.azurewebsites.net/api/Priority/GetList"

export const getPriorityAction = () =>
    dispatch => {
        axios.get(GET_PRIORITY)
            .then(result => {
                dispatch({ type: GET_PRIORITY_SUCCESS, payload: result.data })
            })
            .catch(err => {
                dispatch({ type: GET_PRIORITY_ERROR, payload: "priority'e'e ulaşılamadı" })
            })
    }