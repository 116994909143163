/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useLocation, useParams,useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { FaShare } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { StateChangeTypes } from "downshift";
import { deleteGiftByIdAction, getGiftAction, updateGiftQuantityAction } from "../../../../app/modules/Redux/actions/GiftAction";
import { Link } from "react-router-dom"
import QuantityPicker from './QuantityPicker'
export function AdvanceTablesWidget4({ className }) {

  const { eventid } = useParams();
  const dispatch = useDispatch()
  const history=useHistory()

  useEffect(() => {
    dispatch(getGiftAction(eventid))
  }, [])
  const gifts = useSelector(state => state.gifts.gifts)

  useEffect(() => {
  }, [gifts])

  const location = useLocation();
  const intl = useIntl();

  const handleDelete = (giftId) => {
    dispatch(deleteGiftByIdAction(giftId))
  }

  const handleQuantityDec = (gift) => {
    if (gift.quantity < 2) {
      dispatch(deleteGiftByIdAction(gift.id))
    }
    else {
      dispatch(updateGiftQuantityAction(gift))
    }

  }

  return (


    <div className={`card card-custom ${className} `}>
      {/* Head */}
      <div className="card-header ">
        <h3 className="card-title align-items-center   flex-column">

          <span className="text-muted mt-3 font-weight-bold font-size-sm"></span>

        </h3>
        <div className="card-toolbar">


        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75"><FormattedMessage id="my_wishes" /></span></th>

                  <th style={{ minWidth: "130px" }}><FormattedMessage id="piece" /></th>

                  <th style={{ minWidth: "100px" }}><FormattedMessage id="actual_price" /></th>
                  <th style={{ minWidth: "100px" }}><FormattedMessage id="happening" /></th>
                  <th style={{ minWidth: "100px" }}><FormattedMessage id="gift_detail" /></th>
                  <th style={{ minWidth: "130px" }}><FormattedMessage id="delete" /></th>
                
                </tr>
              </thead>
              <tbody>

                {
                  gifts.map(gift =>
                    <tr>
                      <td className="pl-0 ">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                            <span className="symbol-label">
                              <span>
                                <img style={{width:"50px", height:"50px"}} src={gift.image} />
                              </span>
                            </span>
                          </div>
                          <div>
                            <a  onClick={()=>history.push("/gift/giftdetail/" + gift.id)} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"> {gift.name} </a>

                          </div>
                        </div>
                      </td>

                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                         <QuantityPicker  gift={gift}/>
                        </span>

                      </td>

                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                           {gift.price*gift.quantity}
                        </span>

                      </td>
                      <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          TL 4.750
                        </span>

                      </td>

                      <td>
                        <Link className="btn font-weight-bolder font-size-sm" to={"/gift/giftdetail/" + gift.id} style={{ color: "white", backgroundColor: "#b22f91" }}><FormattedMessage id="edit" /></Link>
                      </td>

                      <td>
                        <a href="" onClick={(e) => {
                          e.preventDefault()
                          handleDelete(gift.id)
                          //handleQuantityDec(gift)
                        }} style={{ color: "white", backgroundColor: "#000" }} className="btn  font-weight-bolder font-size-sm"><FormattedMessage id="delete" /></a>

                      </td>

                      
                    </tr>
                  )
                }
              </tbody>
            </table>


          </div>
        </div>
      </div>
    </div>
  );
}
