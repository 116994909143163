import axios from "axios"
import {
  GET_GIFT_SUCCESS,
  GET_GIFT_ERROR,
  DELETE_GIFT_BY_ID_SUCCESS,
  DELETE_GIFT_BY_ID_ERROR,
  UPDATE_GIFT_QUANTITY_DEC_SUCCESS,
  UPDATE_GIFT_QUANTITY_DEC_ERROR,
  CREATE_GIFT_ERROR,
  CREATE_GIFT_SUCCESS,
  GET_GIFT_WEB_SCRAPING_SUCCESS,
  GET_GIFT_WEB_SCRAPING_ERROR,
  GET_GIFT_DETAIL_BY_ID_SUCCESS,
  GET_GIFT_DETAIL_BY_ID_ERROR,
  GET_GIFT_MARKET_LIST_SUCCESS,
  GET_GIFT_MARKET_LIST_ERROR,
  UPDATE_GIFT_SUCCESS,
  UPDATE_GIFT_ERROR,
  UPDATE_GIFT_QUANTITY_INC_SUCCESS,
  UPDATE_GIFT_QUANTITY_INC_ERROR
} from "../actiontype"
import { baseUrlApi, baseUrlScraping } from "../BaseURL"

const GET_GIFT_EVENT_BY_ID = baseUrlApi + "Gift/GetGiftListByEventId?EventId="
const DELETE_GIFT_BY_ID = baseUrlApi + "Gift/DeleteGiftById?id="
const UPDATE_GIFT = baseUrlApi + "Gift/UpdateGift"
const CREATE_GIFT = baseUrlApi + "Gift/AddGift"
const GET_GIFT_DETAIL_BY_ID = baseUrlApi + "Gift/GetDetailByGift?giftId="
const GET_MARKET_LIST = baseUrlApi + "AcceptedMarketplaces/GetList"
const GET_GIFT_WEB_SCRAPING = baseUrlScraping


export const getGifMarketList = () => dispatch => {
  axios.get(GET_MARKET_LIST)
    .then(result => {
      dispatch({ type: GET_GIFT_MARKET_LIST_SUCCESS, payload: result.data })
    })
    .catch(err => {
      dispatch({ type: GET_GIFT_MARKET_LIST_ERROR, payload: err.response })
    })
}

export const getGiftWebScraping = (link) => dispatch => {
  axios.post(GET_GIFT_WEB_SCRAPING, {
    link
  })
    .then(result => {
      dispatch({ type: GET_GIFT_WEB_SCRAPING_SUCCESS, payload: result.data })
    })
    .catch(err => {
      dispatch({ type: GET_GIFT_WEB_SCRAPING_ERROR, payload: err.response })
    })
}

export const getGiftDetailById = (giftId) => dispatch => {
  axios.get(GET_GIFT_DETAIL_BY_ID + giftId)
    .then(result => {
      dispatch({ type: GET_GIFT_DETAIL_BY_ID_SUCCESS, payload: result.data })
    })
    .catch(err => {
      dispatch({ type: GET_GIFT_DETAIL_BY_ID_ERROR, payload: err.response })
    })
}




export const createGiftAction = (gift, setAlertField, intl, resetAction) => dispatch => {
  axios.post(CREATE_GIFT, {
    eventId: gift.eventId,
    name: gift.name,
    description: gift.description,
    price: gift.price,
    quantity: gift.quantity,
    link: gift.link,
    image: gift.image,
    priorityId: gift.priority
  })
    .then(result => {
      dispatch({ type: CREATE_GIFT_SUCCESS, payload: result.data })
      setAlertField(value => ({ ...value, title: intl.formatMessage({ id: "pass_successfull", }), text: intl.formatMessage({ id: "addgift_true_alert", }), show: true, toPush: true }))
      resetAction.resetForm({})
      resetAction.setlinkState({ imagePath: "", price: "" })
      resetAction.setSelectedPriority(resetAction.defaultSelection)
    })
    .catch(err => {
      dispatch({ type: CREATE_GIFT_ERROR, payload: err.response })
      setAlertField(value => ({ ...value, title: intl.formatMessage({ id: "pass_fail", }), text: intl.formatMessage({ id: "addgift_false_alert", }), show: true }))
    })
}


export const getGiftAction = (eventId) => dispatch => {
  axios.get(GET_GIFT_EVENT_BY_ID + eventId)
    .then(result => {
      dispatch({ type: GET_GIFT_SUCCESS, payload: result.data })
    })
    .catch(error => {
      dispatch({ type: GET_GIFT_ERROR, payload: error.response })
    })
}

export const deleteGiftByIdAction = (giftId) => dispatch => {

  axios.delete(DELETE_GIFT_BY_ID + giftId)
    .then(result => {
      dispatch({ type: DELETE_GIFT_BY_ID_SUCCESS, payload: giftId })
    })
    .catch(error => {
      dispatch({ type: DELETE_GIFT_BY_ID_ERROR, payload: error.response })
    })
}

export const updateGiftAction = (gift, setAlertField, intl) => dispatch => {
  axios.put(UPDATE_GIFT, {
    "id": gift.id,
    "GiftUpdateOptions":
    {
      "eventId": gift.eventId,
      "name": gift.name,
      "description": gift.description,
      "price": gift.price,
      "quantity": gift.quantity,
      "link": gift.link,
      "image": gift.image,
      "priorityId": gift.priorityId
    }
  })
    .then(result => {
      //dispatch({ type: UPDATE_GIFT_SUCCESS, payload: result.data })
      setAlertField(value => ({ ...value, title: "başarılı", text: "kayıt tamam" }))
    })
    .catch(error => {
      //dispatch({ type: UPDATE_GIFT_ERROR, payload: error.response })
      setAlertField(value => ({ ...value, title: "başarisiz", text: "kayıt olmadi" }))
    })
}


export const updateGiftQuantityAction = (gift) => dispatch => {

  axios.put(UPDATE_GIFT, {

    "id": gift.id,
    "GiftUpdateOptions":
    {
      "eventId": gift.eventId,
      "name": gift.name,
      "description": gift.description,
      "price": gift.price,
      "quantity": gift.quantity - 1,
      "link": gift.link,
      "image": gift.image,
      "priorityId": gift.priorityId
    }

  })
    .then(result => {
      dispatch({ type: UPDATE_GIFT_QUANTITY_DEC_SUCCESS, payload: gift.id })
    })
    .catch(error => {
      dispatch({ type: UPDATE_GIFT_QUANTITY_DEC_ERROR, payload: error.response })
    })
}


export const updateGiftQuantityActionInc = (gift) => dispatch => {

  axios.put(UPDATE_GIFT, {

    "id": gift.id,
    "GiftUpdateOptions":
    {
      "eventId": gift.eventId,
      "name": gift.name,
      "description": gift.description,
      "price": gift.price,
      "quantity": gift.quantity + 1,
      "link": gift.link,
      "image": gift.image,
      "priorityId": gift.priorityId
    }

  })
    .then(result => {
      dispatch({ type: UPDATE_GIFT_QUANTITY_INC_SUCCESS, payload: gift.id })
    })
    .catch(error => {
      dispatch({ type: UPDATE_GIFT_QUANTITY_INC_ERROR, payload: error.response })
    })
}


