
import {GET_CATEGORIESANONYMOUS_SUCCESS} from "../actiontype"


const initialAuthState = {
    statusAnonymous: [],
    categoryAnonymous:[]
};

export const categoryAnonymousReducer = (state = initialAuthState.categoryAnonymous, action) => {
  switch (action.type) {
    case GET_CATEGORIESANONYMOUS_SUCCESS: 
      return action.payload
    
    default:
      return state;
  }
}

